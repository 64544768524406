<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { getDecodedRoles } from '@/utils'

import AdminPanel from '@/components/Layout/Panels/Admin'
import PlagiarismPanel from '@/components/Layout/Panels/Plagiarism'

const route = useRoute()
const adminRolePermissions = ['ss_user', 'super_admin', 'ss_event_participant']

const panelType = computed(() => {
  if (route.meta?.plagiarismPanel) return 'plagiarism'
  return 'admin'
})

const getPermissions = (permissions) => {
  if (permissions) return getDecodedRoles().some(role => permissions?.includes(role))
}
</script>

<template>
  <admin-panel v-if="panelType === 'admin' && getPermissions(adminRolePermissions)"/>
  <plagiarism-panel v-if="panelType === 'plagiarism'"/>
</template>

<style lang="scss" scoped>
</style>
