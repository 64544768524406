import axios from 'axios'
import { config } from '@/config'

class PlagiarismReportService {
  getReport({ reportId, includes = "plagiarism_checks" }) {
    return axios.get(`${config.API_URL}/plagiarism-report/${reportId}`, {
      params: {
        includes: includes
      }
    })
  }

  getReportsByEventId({ eventId, includes = "plagiarism_checks,project" }) {
    return axios.get(`${config.API_URL}/plagiarism-reports`, {
        params: {
          includes: includes,
          eventId: eventId
        }
    })
  }

  getReportsByProjectId({ projectId, eventId, includes = "plagiarism_checks", orderBy = "created_at desc", pageSize = 1 }) {
    return axios.get(`${config.API_URL}/plagiarism-reports`, {
      params: {
        includes: includes,
        projectId: projectId,
        eventId: eventId,
        "pagination.orderBy": orderBy,
        "pagination.pageSize": pageSize
      }
    })
  }
}

export default new PlagiarismReportService()
