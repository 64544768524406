export default {
  state: () => ({
    show_panel: false,
    panel_data: {}
  }),
  getters: {
    getPanel: state => state.show_panel,
    getPanelData: state => state.panel_data
  },
  mutations: {
    SHOW_PANEL (state) {
      state.show_panel = !state.show_panel
    },
    CLOSE_PANEL (state) {
        state.show_panel = false
    },
    SET_PANEL_DATA (state, data) {
      state.panel_data = data
    }
  },
  actions: {
    displayPanel ({ commit }) {
      commit ('SHOW_PANEL')
    },
    closePanel({ commit }) {
      commit ('CLOSE_PANEL')
    },
    setPanelData ({ commit }, data) {
      commit ('SET_PANEL_DATA', data)
    }
  }
}
