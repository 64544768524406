// Packages
import { createRouter, createWebHistory } from 'vue-router'
// Dashboard Pages
import Settings from '@/pages/Dashboard/TeamSetting'
import Events from '@/pages/Dashboard/Events'
import Subscriptions from '@/pages/Dashboard/Subscriptions'
import Forms from '@/pages/Dashboard/Forms'
import Support from '@/pages/Dashboard/Support'
import Users from '@/pages/Dashboard/Users'
import Version from '@/pages/Dashboard/Version'
import Payments from '@/pages/Dashboard/Payments'
import Analytics from '@/pages/Dashboard/Analytics'
import AcceptUserInvitation from '@/pages/Dashboard/AcceptUserInvitation'
// Event Builder Pages
import General from '@/pages/EventBuilder/General'
import Branding from '@/pages/EventBuilder/Branding'
import ProjectTemplates from '@/pages/EventBuilder/ProjectTemplates'
import Resources from '@/pages/EventBuilder/Resources'
import Participants from '@/pages/EventBuilder/Participants'
import Submissions from '@/pages/EventBuilder/Submissions'
import Timeline from '@/pages/EventBuilder/Timeline'
import Template from '@/pages/EventBuilder/Template'
import Project from '@/pages/EventBuilder/Project'
import Categories from '@/pages/EventBuilder/Categories'
import CreateAwards from '@/pages/EventBuilder/Awards/CreateAwards'
import GiveAwards from '@/pages/EventBuilder/Awards/GiveAwards'
import Sponsors from '@/pages/EventBuilder/Sponsors'
import Preview from '@/pages/EventBuilder/Preview'
import Plagiarism from '@/pages/EventBuilder/Plagiarism'

import PlagiarismReport from '@/pages/EventBuilder/PlagiarismReport'
// Page Status Errors
import NotFound from '@/pages/NotFound'
import ParentAccess from '@/pages/NotFound/Parent'
import UserAccess from '@/pages/NotFound/User'
import NotAccess from '@/pages/NotFound/NotAccess'
// Auth
import Login from '@/pages/Auth/Login'
import Register from '@/pages/Auth/Register'
import ResetPassword from '@/pages/Auth/ResetPassword'
import VerifyEmail from '@/pages/Auth/VerifyEmail'
import ThirdPartySso from '@/pages/Auth/ThirdPartySso'
import Callback from '@/pages/Auth/Callback'
// Guards
import { authGuard, adminAuthGuard } from "@/utils"
import { trackingIntercepter } from '@/utils/tracking-intercepter'
// Routes
const routes = [
  // Dashboard
  {
    path: '/',
    name: 'Home',
    redirect: '/events',
    beforeEnter: [authGuard]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      title: 'Team Settings'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
    meta: {
      title: 'Analytics'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
    meta: {
      title: 'Events'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
    meta: {
      title: 'Payments'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions,
    meta: {
      title: 'Payments & Plans'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/forms',
    name: 'Forms',
    component: Forms,
    meta: {
      title: 'Forms'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      title: 'Help & Support'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      title: 'Admins'
    },
    beforeEnter: [authGuard]
  },
  // Event Builder
  {
    path: '/event-builder/:slug',
    redirect: to => {
      return { name: "General", query: { q: to.params.searchText }}
    }
  },
  {
    path: '/event-builder/:slug/general',
    name: 'General',
    component: General,
    meta: {
      title: 'EventBuilder | General'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/event-builder/:slug/branding',
    name: 'Branding',
    component: Branding,
    meta: {
      title: 'EventBuilder | Design & Branding'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/event-builder/:slug/project-templates',
    meta: {
      title: 'EventBuilder | Project'
    },
    beforeEnter: [authGuard],
    children: [
      {
        path: '',
        name: 'ProjectTemplates',
        component: ProjectTemplates,
        meta: {
          title: 'EventBuilder | Project'
        },
      },
      {
        path: 'project/:id',
        name: 'Project',
        component: Project,
        meta: {
          title: 'EventBuilder | Project',
          adminPanel: true
        },
        beforeEnter: [authGuard]
      },
      {
        path: 'template/:id',
        name: 'Template',
        component: Template,
        meta: {
          title: 'EventBuilder | Template'
        },
        beforeEnter: [authGuard]
      },
      {
        path: 'preview/:projectId',
        name: 'Preview',
        component: Preview,
        meta: {
          title: 'EventBuilder | Preview',
          hideSidebar: true,
          participantPanel: true
        },
        beforeEnter: [authGuard]
      },
    ]
  },
  {
    path: '/event-builder/:slug/categories',
    name: 'Categories',
    component: Categories,
    meta: {
      title: 'EventBuilder | Categories'
    }
  },
  {
    path: '/event-builder/:slug/resources',
    name: 'Resources',
    component: Resources,
    meta: {
      title: 'EventBuilder | Resources'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/event-builder/:slug/sponsors',
    name: 'Sponsors',
    component: Sponsors,
    meta: {
      title: 'EventBuilder | Sponsors'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/event-builder/:slug/participants',
    name: 'Participants',
    component: Participants,
    meta: {
      title: 'EventBuilder | Participants'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/event-builder/:slug/submissions',
    meta: {
      title: 'EventBuilder | Project Submissions',
      plagiarismPanel: true
    },
    beforeEnter: [authGuard],
    children: [
      {
        path: '',
        name: 'Submissions',
        component: Submissions,
        meta: {
          title: 'EventBuilder | Project Submissions'
        },
      },
      {
        path: 'project/:projectId/plagiarism-report',
        name: 'PlagiarismReport',
        component: PlagiarismReport,
        meta: {
          title: 'EventBuilder | Plagiarism Report',
          layout: 'empty'
        },
      }
    ]
  },
  {
    path: '/event-builder/:slug/plagiarism',
    name: 'Plagiarism',
    component: Plagiarism,
    meta: {
      title: 'EventBuilder | Scans'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/event-builder/:slug/createawards',
    name: 'CreateAwards',
    component: CreateAwards,
    meta: {
      title: 'EventBuilder | Give Awards'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/event-builder/:slug/giveawards',
    name: 'GiveAwards',
    component: GiveAwards,
    meta: {
      title: 'EventBuilder | Give Awards'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/event-builder/:slug/timeline',
    name: 'Timeline',
    component: Timeline,
    meta: {
      title: 'EventBuilder | Timeline'
    },
    beforeEnter: [authGuard]
  },
  {
    path: '/event-builder/:slug/template/undefined', //Temp fix for undefined
    redirect: to => {
      return `/event-builder/${to.params.slug}/projects`
    }
  },
  {
    path: '/event-builder/:slug/project/undefined', //Temp fix for undefined
    redirect: to => {
      return `/event-builder/${to.params.slug}/projects`
    }
  },
  // Auth
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/signup',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Signup'
    }
  },
  {
    path: '/password/reset',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      title: 'Reset Password'
    }
  },
  {
    path: '/login/callback',
    name: 'callback',
    component: Callback,
    meta: {
      title: 'Callback',
      layout: 'empty'
    }
  },
  {
    path: '/verify',
    name: 'VerifyEmail',
    component: VerifyEmail,
    meta: {
      title: 'Verify Your Email'
    }
  },
  {
    path: '/third-party/sso',
    name: 'ThirdPartySso',
    component: ThirdPartySso,
    meta: {
      title: 'Third Party SSO'
    }
  },
  // Others
  {
    path: '/pricing',
    name: 'Pricing',
    redirect: '/events'
  },
  {
    path: '/version',
    name: 'Version',
    component: Version,
    meta: {
      title: 'Version'
    },
    before: [authGuard, adminAuthGuard],
  },
  {
    path: '/parent-access',
    name: 'ParentAccess',
    component: ParentAccess,
    meta: {
      title: '401 Access Denied'
    }
  },
  {
    path: '/user-access',
    name: 'UserAccess',
    component: UserAccess,
    meta: {
      title: '401 Access Denied'
    }
  },
  {
    path: '/notFound',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '404 Not Found',
      hideSidebar: true
    }
  },
  {
    path: '/notAccess',
    name: 'NotAccess',
    component: NotAccess,
    meta: {
      title: '401 Access Denied',
        hideSidebar: true
    }
  },
  {
    path: '/acceptUserInvitation',
    name: 'AcceptUserInvitation',
    component: AcceptUserInvitation,
    meta: {
      title: 'Accept User Invitation'
    }
  },
  {
    path: '/:catchAll(.*)', // Invalid routes
    redirect: '/notFound'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, form, next) => {
  trackingIntercepter(to)
  document.title = `${to.meta.title}`
  next()
})

export default router
