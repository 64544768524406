<script setup>
import { computed, reactive } from 'vue'
import store from '@/store'
import { pbAdminCard } from 'pb-components'
import BaseButton from '@/components/Base/Button/BaseButton'
import BaseTag from '@/components/Base/Tags'
import { generateProjectLink, processStatuses, projectStatuses, submissionStatusType } from '@/utils'

const previewPublic = reactive({
  title: 'View / Edit Project as Admin',
  name: 'preview',
  hasDropdown: false,
  type: 'public'
})

const isPlagiarismPanelActive = computed(() => {
  return store.getters.getPanel
})

const getPanelData = computed(() => {
  return store.getters.getPanelData
})

const projectDeclineReason = computed(() => {
  const project = store.getters.getProjectSubmissionById(getPanelData.value.id)
  return project?.projectStatus?.notes
})

const projectDeclineLastUpdated = computed(() => {
  const project = store.getters.getProjectSubmissionById(getPanelData.value.id)
  const dateString = project?.projectStatus?.notifiedAt
  if (!dateString) return null
  const date = new Date(dateString)
  return date.toLocaleString()
})

const closePanel = () => {
  store.dispatch('closePanel')
}

const projectResendStatus = (projectId) => {
  return store.getters.getParticipantReNotifyStatus(projectId)
}

const isParticipantsNotified = computed(() => {
  return store.getters.getParticipantsNotified
})

const getStatus = (status) => {
  return status?.replace('PROJECT_STATUSES_', '').toLowerCase()
}

const resendProjectStatusEmail = (projectId) => {
  store.dispatch('ToggleModalGeneralConfirm', {
    title: 'Are You Sure You Want To Notify Student?',
    description: 'As a reminder, if student has been notified previously, they will receive an email again.',
    confirmButton: 'Yes, Notify Anyway',
    cancelButton: 'No',
    confirmAction: () => {
      store.dispatch('resentProjectSubmissionsStatusEmail', projectId).then(() => {
        store.dispatch('triggerToast', { message: 'Project status email sent', type: 'success' })
      }).catch(() => {
        store.dispatch('triggerToast', { message: 'Failed to send project status email', type: 'error' })
      }).finally(() => {
        store.dispatch('ResetModal')
      })
    },
    cancelAction: () => {
      store.dispatch('ResetModal')
    }
  })
}

const showDeclineReasonModal = (projectId) => {
  store.dispatch('ToggleProjectDeclineReasonModal', {
    confirmAction: async () => {
      await store.dispatch('ResetModal')
    },
    cancelAction: async () => {
      await store.dispatch('ResetModal')
    },
    projectId: projectId
  })
}
</script>

<template>
  <aside class="plagiarism-panel"
         :class="{
    'plagiarism-panel-open' : isPlagiarismPanelActive,
    'plagiarism-panel-close' : !isPlagiarismPanelActive
  }">
    <div class="cursor-pointer"
        @click.prevent="closePanel">
      <i class="fa-regular fa-xmark fa-xl" />
    </div>
    <div class="plagiarism-panel-container">
      <div class="plagiarism-panel-container-header">
        <i class="fa-light fa-circle-info fa-xl plagiarism-panel-container-header-icon" />
        <h2 class="plagiarism-panel-container-header-text">
          Submission Details
        </h2>
      </div>
      <p class="plagiarism-panel-container-description">
        {{ getPanelData.title }}
      </p>
      <pb-admin-card :path="generateProjectLink(getPanelData.id)"
                     :card-data="previewPublic"/>
     <pb-admin-card :has-dropdown="true">
       <template #admin-dropdown-header-title>
          Project Status
       </template>
       <template #admin-dropdown-content>
         <div class="py-4">
          <base-tag :text="getStatus(getPanelData.submissionStatus)" />
         </div>
         <div v-if="getPanelData.submissionStatus === submissionStatusType.DECLINED">
           <div class="plagiarism-panel-container-reason-title">
             <h6>
               Decline Reason(s)
             </h6>
             <div class="plagiarism-panel-container-reason-edit"
                  @click="() => showDeclineReasonModal(getPanelData.id)">
                <i class="fa-regular fa-edit fa-lg" />
             </div>
           </div>
           <div class="plagiarism-panel-container-reason-list">
             {{ projectDeclineReason }}
           </div>
         </div>
         <div v-if="projectDeclineLastUpdated">
           <h6 class="plagiarism-panel-container-reason-title">
             Decision Notification Status
           </h6>
           <p class="plagiarism-panel-container-reason-time">
             Sent {{ projectDeclineLastUpdated }}
           </p>
         </div>
         <base-button text="Resend Decision"
                      class-name="plagiarism-panel-container-reason-button"
                      :is-loading="projectResendStatus(getPanelData.id) === processStatuses.LOADING"
                      :disabled="!isParticipantsNotified || projectResendStatus(getPanelData.id) === processStatuses.LOADING || [projectStatuses.DRAFT, projectStatuses.SUBMITTED].includes(getPanelData.submissionStatus)"
                      :functions="() => resendProjectStatusEmail(getPanelData.id)"/>
       </template>
     </pb-admin-card>
    </div>
  </aside >
</template>

<style lang="scss" scoped>
.plagiarism-panel {
  @apply fixed right-0 z-10 shadow-2xl h-screen bg-white p-4 transition-all ease-in-out duration-300;
  &-open {
    @apply w-[22.6rem];
  }
  &-close {
    @apply w-0 -right-[2rem];
  }
  &-container {
    @apply px-4 py-16;
    &-header {
      @apply flex flex-row items-center;
      &-icon {
        @apply w-[3rem] h-[3rem] bg-product-100 rounded-md flex items-center justify-center mr-5;
      }
      &-text {
        @apply font-medium text-h2;
      }
    }
    &-description {
      @apply text-p-md text-blacks-500 font-medium py-4 line-clamp-2;
    }
    &-reason {
      &-title {
        @apply font-medium text-h6 flex justify-between;
      }
      &-edit {
        @apply cursor-pointer;
      }
      &-list {
        @apply text-p-sm list-disc font-light text-blacks-300 mb-4;
      }
      &-time {
        @apply text-p-sm font-light text-blacks-300 mb-4;
      }
      &-button {
        @apply w-[90%] py-2.5;
      }
    }
  }
}

:deep(.tag) {
  @apply w-[130px] h-[36px] font-medium flex justify-start text-p-md;
}
</style>
