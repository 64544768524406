<script setup>
// import packages
import { computed } from 'vue'
// Route
import { useRoute } from 'vue-router'
// Store
import store from '@/store'
// import components
import Navbar from '@/components/Layout/Navbars/Top'
import Sidebar from '@/components/Layout/Sidebar'
import Modal from '@/components/Modals/'
import BaseLayout from '@/components/Layout/BaseLayout'

const route = useRoute()

// Check if error page
const isErrorPage = computed(() => {
  return route.name === 'NotFound' || route.name === 'NotAccess'
})
// Sidebar Button
const sidebarBtn = computed(() => {
  return store.getters.sideMenu
})
const sideMenu = computed(() => {
  return store.getters.sideMenu
})
const sideBarType = computed(() => {
  return store.getters.sidebarType
})

const isDashboard = computed(() => {
  return !route.path.includes('event-builder') || sideBarType.value === 'dashboard'
})

const isEventBuilder = computed(() => {
  return route.path.includes('event-builder') || sideBarType.value === 'builder'
})
const isPreview = computed(() => {
  return route.name === 'Preview'
})
</script>

<template>
  <base-layout>
    <template #sidebar>
      <sidebar :sidebar-btn="sidebarBtn"/>
    </template>
    <template #navigation>
      <navbar :sidebar-btn="sidebarBtn"/>
    </template>
    <template #content>
      <main :class="{
              'content' : store.getters.isLoggedIn,
              'content_sideMenu' : !sideMenu && store.getters.isLoggedIn && !isErrorPage && !isPreview,
              'content_sideMenu_notActive' : sideMenu && store.getters.isLoggedIn && !isErrorPage,
              'content_dashboard' : isDashboard,
              'content_builder' : isEventBuilder,
              'content_preview': isPreview
            }">
        <modal />
        <slot />
      </main>
    </template>
  </base-layout>
</template>

<style lang="scss" scoped>
.background {
  @apply min-h-screen overflow-hidden relative bg-background-100;
}
.content {
  @apply px-4;
  &_preview {
    //@apply lg:pl-[9.5rem];
  }
  &_sideMenu {
    @apply md:pl-[20rem];
    &_notActive {
      @apply md:pl-[8rem];
    }
  }
  &_dashboard {
    @apply pt-16;
  }
  &_builder {
    @apply pt-4 md:ml-8;
  }
}
</style>
