const NUMBER_OF_EVENTS = 'number_of_events'
const NUMBER_OF_APPROVED_PROJECTS = 'number_of_approved_projects'
const EVENT_DURATION = 'event_duration'
const CAN_ARCHIVE_PROJECT = 'can_archive_project'
const CAN_ARCHIVE_EVENT = 'can_archive_event'
const ENABLE_JUDGING = 'enable_judging'
const ENABLE_AWARDS = 'enable_awards'
const ENABLE_LIVE_STREAMS = 'enable_live_streams'
const ENABLE_SPONSOR = 'enable_sponsor'
const CAN_CUSTOMIZE_PROJECT_TEMPLATE = 'can_customize_project_template'
const ENABLE_CATEGORIES = 'enable_categories'
const DISABLE_ADS = 'disable_ads'
const NUMBER_OF_PARTICIPANTS = 'number_of_participants'
const NUMBER_OF_ADMIN_SEATS = 'number_of_admin_seats'
const ENABLE_EVENT_NOTIFICATION = 'enable_event_notification'
const ENABLE_CUSTOM_REGISTRATION_FORM = 'enable_custom_registration_form'
const ENABLE_ON_APP_SUPPORT = 'enable_on_app_support'
const ENABLE_CUSTOM_BANNER = 'enable_custom_banner'
const CAN_CUSTOMIZE_EVENT_NAME = 'can_customize_event_name'
const CAN_CUSTOMIZE_EVENT_SLUG = 'can_customize_event_slug'
const ENABLE_PLAGIARISM_CHECK = 'enable_plagiarism'

const EventFeatureCodes = [
    NUMBER_OF_EVENTS,
    NUMBER_OF_APPROVED_PROJECTS,
    EVENT_DURATION,
    CAN_ARCHIVE_PROJECT,
    CAN_ARCHIVE_EVENT,
    ENABLE_JUDGING,
    ENABLE_AWARDS,
    ENABLE_LIVE_STREAMS,
    ENABLE_SPONSOR,
    CAN_CUSTOMIZE_PROJECT_TEMPLATE,
    ENABLE_CATEGORIES,
    DISABLE_ADS,
    NUMBER_OF_PARTICIPANTS,
    NUMBER_OF_ADMIN_SEATS,
    ENABLE_EVENT_NOTIFICATION,
    ENABLE_CUSTOM_REGISTRATION_FORM,
    ENABLE_ON_APP_SUPPORT,
    ENABLE_CUSTOM_BANNER,
    CAN_CUSTOMIZE_EVENT_NAME,
    CAN_CUSTOMIZE_EVENT_SLUG,
    ENABLE_PLAGIARISM_CHECK
]

export const eventFeatureCodes = {
    NUMBER_OF_EVENTS,
    NUMBER_OF_APPROVED_PROJECTS,
    EVENT_DURATION,
    CAN_ARCHIVE_PROJECT,
    CAN_ARCHIVE_EVENT,
    ENABLE_JUDGING,
    ENABLE_AWARDS,
    ENABLE_LIVE_STREAMS,
    ENABLE_SPONSOR,
    CAN_CUSTOMIZE_PROJECT_TEMPLATE,
    ENABLE_CATEGORIES,
    DISABLE_ADS,
    NUMBER_OF_PARTICIPANTS,
    NUMBER_OF_ADMIN_SEATS,
    ENABLE_EVENT_NOTIFICATION,
    ENABLE_CUSTOM_REGISTRATION_FORM,
    ENABLE_ON_APP_SUPPORT,
    ENABLE_CUSTOM_BANNER,
    CAN_CUSTOMIZE_EVENT_NAME,
    CAN_CUSTOMIZE_EVENT_SLUG,
    ENABLE_PLAGIARISM_CHECK,
    EventFeatureCodes
}
