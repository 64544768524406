import PlagiarismReportService from '@/services/plagiarism-report'
import { processStatuses } from '@/utils'

export default {
  state: () => ({
    reports: [],
    status: processStatuses.IDLE,
    totalReports: 0,
    error: null,
    nextPageToken: 0,
    // sort
    sort: 'id',
    isDesc: true
  }),
  getters: {
    getPlagiarismReports: state => {
      return state.reports
    },
    getPlagiarismReportStatus: state => {
      return state.status
    },
    getPlagiarismReportError: state => {
      return state.error
    },
    getPlagiarismReportsCount: state => {
      return state.totalReports
    }
  },
  mutations: {
    PLAGIARISM_REPORTS_LOADED(state, reports) {
      state.reports = reports
      state.status = processStatuses.LOADED
    },
    PLAGIARISM_REPORTS_LOADING(state) {
      state.status = processStatuses.LOADING
    },
    PLAGIARISM_REPORTS_ERROR(state, error) {
      state.status = processStatuses.ERROR
      state.error = error
    },
    PLAGIARISM_REPORTS_PAGE(state, { nextPageToken, total }) {
      state.nextPageToken = nextPageToken
      state.totalReports = total
    },
    SET_PLAGIARISM_REPORTS_SORT_BY(state, sortBy) {
      state.sort = sortBy
    },
    TOGGLE_PLAGIARISM_REPORTS_DESC(state) {
      state.isDesc = !state.isDesc
    }
  },
  actions: {
    async fetchPlagiarismReports({commit, rootGetters}) {
      if (rootGetters.ebEventStatus !== processStatuses.LOADED) {
        throw new Error('Event not loaded')
      }
      commit('PLAGIARISM_REPORTS_LOADING')
      try {
        const result = await PlagiarismReportService.getReportsByEventId({ eventId: rootGetters.ebEventId })
        commit('PLAGIARISM_REPORTS_LOADED', result.data.reports)
        if (result.data.pagination) {
          commit('PLAGIARISM_REPORTS_PAGE', { nextPageToken: result.data.pagination.nextPageToken, total: result.data.pagination.total })
        }
      } catch (e) {
        console.error(e)
        commit('PLAGIARISM_REPORTS_ERROR', e)
      }
    },
    async setPlagiarismReportsSortBy({commit}, sortBy) {
      commit('SET_PLAGIARISM_REPORTS_SORT_BY', sortBy)
    }
  }
}
