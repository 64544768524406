<script setup>
import { computed, onMounted, reactive } from 'vue'
import store from '@/store'
import ModalLayout from '@/components/Modals/ModalBase'
import BaseButton from '@/components/Base/Button/BaseButton'
import { handleBackButton } from '@/utils'
import TextareaField from '@/components/Base/Fields/TextareaField'

const projectId = computed(() => {
  return store.getters.modalData.data.projectId
})

const project = computed(() => {
  return store.getters.getProjectSubmissionById(projectId.value)
})

const declineReasonData = reactive({
  notes: project.value?.projectStatus?.notes ?? "",
  projectId: projectId.value,
  validationError: null
})

const isUpdated = computed(() => {
  return declineReasonData.notes !== project.value?.projectStatus?.notes
})

const confirmAction = async () => {
  if (isUpdated.value) {
    console.log('Changes', declineReasonData.notes)
    const projectData = {
      projectId: projectId.value,
      notes: declineReasonData.notes,
      updateMask: "notes"
    }
    await store.dispatch('UpdateProjectStatuses', projectData)
  }
  await store.dispatch('ConfirmAction')
}

const cancelAction = () => {
  store.dispatch('CancelAction')
}

onMounted(() => {
  handleBackButton()
})
</script>

<template>
  <modal-layout modal-class="bg-white modal-decline" :is-close="true">
    <template #modal-title>
      <div class="modal-decline-header">
        <h1 class="modal-decline-title">
          Why are you declining this project?
        </h1>
      </div>
    </template>
    <template #modal-subContent>
      <div class="modal-decline-body">
        <p class="modal-decline-content">

        </p>
      </div>
    </template>

    <!-- cancel feedback with textarea section -->
    <template #modal-content>
      <div class="confirm-message">
        <p class="confirm-why-decline">
          Type your reason below. After you send your decisions, the participants will have the opportunity to resubmit their project for review.
        </p>
        <textarea-field v-model="declineReasonData.notes"
                        label-text=""
                        :model-value="declineReasonData.notes"
                        input-place-holder="Write your feedback"
                        class-name="border rounded-2xl border-greys-200 h-[5rem] text-p-md"
                        :errors="declineReasonData.validationError"/>
      </div>
    </template>

    <!-- Back to Events & Submit Buttons -->
    <template #modal-button>
      <div class="modal-decline-btn-container">
          <base-button :functions="cancelAction"
                       text="Cancel"
                       class-name="modal-decline-btn modal-decline-btn-back"
                       btn-type="secondary"/>
          <base-button :functions="confirmAction"
                       text="Save"
                       :disabled="!isUpdated"
                       class-name="modal-decline-btn modal-decline-btn-approve"
                       btn-type="primary"/>
        </div>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.modal {
  &-decline {
    @apply md:h-auto overflow-y-scroll max-w-md md:p-7 p-4 rounded-b-none md:rounded-2xl;
    &-header {
      @apply flex flex-col w-full justify-center items-center;
    }
    &-title {
      @apply normal-case font-medium text-h4 text-center text-blacks-500 pb-3;
    }
    &-body {
      @apply flex flex-col justify-center items-center;
    }
    &-content {
      @apply text-blacks-300 text-p-md text-center;
    }
    &-btn {
      @apply w-[12rem] h-[3rem] mx-1 normal-case;
      &-container {
        @apply w-full flex flex-row justify-center mt-8;
      }
      &-back {
        @apply text-primary border-primary font-medium hover:border-transparent hover:bg-white hover:text-product-500;
      }
      &-approve {
        @apply bg-other-green text-white font-medium hover:opacity-80;
      }
      &-delete {
        @apply bg-other-red text-white font-medium hover:opacity-80;
      }
    }
  }
}
</style>
