<script setup>
// Required Packages
import { computed } from 'vue'
// Store
import store from '@/store'
// Modals
import UserModal from '@/components/Modals/User'
import EventModal from '@/components/Modals/Event'
import SupportModal from '@/components/Modals/Support'
import PlanModal from '@/components/Modals/Plan'
import VideoModal from '@/components/Modals/Video'
import ConfirmEventModal from '@/components/Modals/Confirmation/Confirm'
import ConfirmTemplateModal from '@/components/Modals/Confirmation/Template'
import ResourceModal from '@/components/Modals/Resource'
import ResourceDeleteModal from '@/components/Modals/Resource/Delete'
import GoLive from '@/components/Modals/Event/GoLive';
import EventBegin from '@/components/Modals/Event/EventBegin';
import EventLive from '@/components/Modals/Event/EventLive';
import EventCancelled from '@/components/Modals/Event/EventCancelled'
import ResourcePreviewModal from '@/components/Modals/Resource/Preview'
import DeleteEventModal from '@/components/Modals/Confirmation/Delete'
import EventCreate from '@/components/Modals/Event/CreateEvent'
import EventDraft from '@/components/Modals/Event/EventDraft'
import EventLaunch from '@/components/Modals/Event/EventLaunch'
import EventUpgrade from '@/components/Modals/Event/UpgradeEvent'
import PaymentReceipt from '@/components/Modals/Payment'
import UnsavedAlert from '@/components/Modals/Event/UnsavedAlert'
import CategoriesLib from '@/components/Modals/Categories/Libraries'
import TransferOwnership from '@/components/Modals/User/TransferOwnership'
import RemoveAccess from '@/components/Modals/User/RemoveAccess'
import InviteUser from '@/components/Modals/User/InviteUser'
import IconsModal from '@/components/Modals/Icons'
import InviteAdminEmailPreview from '@/components/Modals/User/InviteAdminEmailPreview'
import AssignAward from '@/components/Modals/Award/AssignAward'
import ConfirmModal from '@/components/Modals/Confirmation'
import InviteParticipants from '@/components/Modals/Participant/InviteParticipants'
import RemoveParticipants from '@/components/Modals/Participant/RemoveParticipants'
import InviteParticipantsEmailPreview from '@/components/Modals/Participant/InviteParticipantsEmailPreview'
import IntegrationConfirmation from '@/components/Modals/Participant/IntegrationConfirmation'
import DeclineReason from '@/components/Modals/Submission/DeclineReason'

// Modal
const modal = computed(() => {
  return store.getters.modal || false
})

const modalType = computed(() => {
  return store.getters.typeOfModal
})

const isUser = computed(() => {
  return ['login', 'register', 'password', 'verify', 'logout', 'profile', 'confirmEvent', 'confirmTemplate',
          'forget-password', 'check-reset-password-email'].includes(modalType.value)
})
</script>

<template>
  <div v-if="modal"
    class="modal" :class="{'modal-preview': modalType === 'resourcePreview'}">
    <user-modal v-if="isUser"/>
    <event-modal v-if="modalType === 'event'" />
    <plan-modal v-if="['plan', 'upgrade'].includes(modalType)" />
    <support-modal v-if="modalType === 'support'" />
    <video-modal v-if="modalType === 'video'"/>
    <!-- Confirmation -->
    <confirm-event-modal v-if="modalType === 'confirmEvent'"/>
    <confirm-template-modal v-if="modalType === 'confirmTemplate'"/>
    <!-- Resources -->
    <resource-modal v-if="modalType === 'resource'" />
    <resource-delete-modal v-if="modalType === 'resource_delete'" />
    <!-- GO Live -->
    <go-live v-if="modalType === 'goLive'" />
    <!-- Events -->
    <event-begin v-if="modalType === 'eventBegin'" />
    <event-live v-if="modalType === 'eventLive'" />
    <resource-preview-modal v-if="modalType === 'resourcePreview'" />
    <event-cancelled v-if="modalType === 'eventCancelled'" />
    <delete-event-modal v-if="modalType === 'deleteEvent'" />
    <event-create v-if="modalType === 'eventCreate'" />
    <unsaved-alert v-if="modalType === 'unsavedAlert'" />
    <event-draft v-if="modalType === 'eventDraft'" />
    <event-launch v-if="modalType === 'eventLaunch'" />
    <event-upgrade v-if="modalType === 'eventUpgrade'" />
    <!-- Payments -->
    <payment-receipt v-if="modalType === 'paymentReceipt'" />
    <!-- Categories -->
    <categories-lib v-if="modalType === 'categoriesLibrary'" />
    <transfer-ownership v-if="modalType === 'transferOwnership'" />
    <remove-access v-if="modalType === 'removeAccess'" />
    <invite-user v-if="modalType === 'inviteUser'" />
    <icons-modal v-if="['icons', 'awards', 'sponsors'].includes(modalType)" />
    <invite-admin-email-preview v-if="modalType === 'inviteAdminEmailPreview'" />
    <assign-award v-if="modalType === 'assignAwards'" />
    <confirm-modal v-if="modalType === 'confirm'" />
    <invite-participants v-if="modalType === 'inviteParticipants'" />
    <remove-participants v-if="modalType === 'removeParticipants'" />
    <invite-participants-email-preview v-if="modalType === 'inviteParticipantsEmailPreview'" />
    <integration-confirmation v-if="modalType === 'integrationConfirmation'" />
    <decline-reason v-if="modalType === 'projectDeclineReason'" />
  </div>
</template>

<style lang="scss" scoped>
.modal {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 font-['Cera_Pro'];
  &-preview {
    @apply w-full h-full flex justify-center items-center;
  }
}
.paymentReceipt {
  &-preview {
    &-modal {
      @apply fixed inset-0 w-full h-full overflow-hidden flex-col	justify-center items-center translate-x-0 translate-y-0;
    }
  }
}
</style>
