<script setup>
// Skip using the store, for later moving to the shared components package
import PlagiarismReportService from '@/services/plagiarism-report'
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import PlagiarismSearch from './PlagiarismProviders/PlagiarismSearch'
import Tineye from './PlagiarismProviders/Tineye'
import { plagiarismStatuses, generateProjectLink, processStatuses } from '@/utils'
import store from '@/store'
import Loading from '@/components/Base/Loading'

const route = useRoute()

const plagiarismReport = ref(null)

const isLoading = ref(true)

const orderedSourceGroup = computed(() => {
  if (!plagiarismReport.value?.plagiarismChecks) {
    return []
  }

  const orderedSubgroups = []
  plagiarismReport.value?.plagiarismChecks.filter(m => m.status === plagiarismStatuses.COMPLETED).sort((a, b) => a.priority - b.priority).forEach(m => {
    if (!orderedSubgroups.includes(m.sourceGroup)) {
      orderedSubgroups.push(m.sourceGroup)
    }
  })
  return orderedSubgroups
})

const groupedPlagiarismChecks = computed(() => {
  const grouppedResults = plagiarismReport.value?.plagiarismChecks.filter(m => m.status === plagiarismStatuses.COMPLETED).reduce((acc, curr) => {
    if (!acc[curr.sourceGroup]) {
      acc[curr.sourceGroup] = []
    }
    acc[curr.sourceGroup].push(curr)
    return acc
  }, {})

  // sort each groups by priority
  for (const group in grouppedResults) {
    grouppedResults[group].sort((a, b) => a.priority - b.priority)
  }

  return grouppedResults
})

const providerComponent = (provider) => {
  switch (provider) {
  case 'plagiarismsearch':
    return PlagiarismSearch
  case 'tineye':
    return Tineye
  default:
    return null
  }
}

onMounted(async () => {
  const slug = route.params.slug
  if (store.getters.ebEventSlug !== slug || store.getters.ebEventStatus !== processStatuses.LOADED) {
    await store.dispatch('getEvent', slug)
  }

  // Skipping the Store for later moving to the shared components package
  if (route.params.projectId) {
    isLoading.value = true
    PlagiarismReportService.getReportsByProjectId({
      projectId: route.params.projectId,
      eventId: store.getters.ebEventId,
      includes: "plagiarism_checks,project"
    }).then((response) => {
      if (response.data.reports.length === 0) {
        console.log('No plagiarism report found')
        return
      }
      isLoading.value = false
      plagiarismReport.value = response.data.reports[0]
    })
  } else {
    console.log('No project id found')
  }
})

const membershipsToTeamNames = (memberships) => {
  return memberships.map(membership => {
    if (membership.user.firstName.trim() && membership.user.lastName.trim()) {
      return membership.user.firstName + ' ' + membership.user.lastName
    }
    return membership.user.userName
  }).join(', ')
}
</script>

<template>
  <div v-if="isLoading"
        class="h-screen flex justify-center items-center">
    <loading size="xxl" />
  </div>
  <div v-else
       class="report">
    <div class="report-summary"
         :class="plagiarismReport?.result === 'review' ? 'review' : 'pass'">
      <div class="report-summary-generated"><b>Report Generated</b> {{ plagiarismReport?.createdAt.split('T')[0] }}</div>
      <div class="report-summary-content">
        <div class="report-summary-title">
          <div class="report-summary-title-report">Similarity Report</div>
          <div class="report-summary-title-project"><a :href="generateProjectLink(plagiarismReport?.projectId)">{{ plagiarismReport?.title }}</a></div>
          <div v-if="plagiarismReport?.project?.memberships" class="report-summary-title-project-teams"><b>Project Team</b> {{ membershipsToTeamNames(plagiarismReport.project?.memberships) }}</div>
        </div>
        <div v-if="plagiarismReport?.result === 'review'"
             class="report-summary-status">
            <div class="report-summary-status-icon">
              <i class="fa-circle-exclamation fa-solid fa-xl" />
            </div>
            <div class="report-summary-status-text">Needs Review</div>
        </div>
        <div v-else
             class="report-summary-status">
          <div class="report-summary-status-icon">
            <i class="fa-circle-check fa-solid fa-xl" />
          </div>
          <div class="report-summary-status-text">Pass</div>
        </div>
      </div>
      <div class="report-summary-disclaimer">
        <p><b>Disclaimer:</b> ProjectBoard is using <a href="https://tineye.com" target="_blank">TinEye Reverse Image Search</a> and <a href="https://plagiarismsearch.com" target="_blank">plagiarismsearch.com</a>, third-party tools that scans billions of images and text online. This report highlights content that may require further review for potential plagiarism or improper citation. Please note that these flags are not definitive, and additional verification and discussion may be needed to confirm any issues.</p>
      </div>
    </div>
    <div class="report-header">
      <div class="report-header-title">
        <h1>{{ plagiarismReport?.title }}</h1>
        <p v-if="plagiarismReport?.project?.memberships">{{ membershipsToTeamNames(plagiarismReport.project?.memberships) }}</p>
      </div>
      <div class="report-header-timestamp">
        <b>Report Generated</b>
        <div>{{ plagiarismReport?.createdAt.split('T')[0] }}</div>
      </div>
    </div>
    <hr class="mt-4 mb-6" />
    <div v-if="plagiarismReport && plagiarismReport.plagiarismChecks">
      <div v-for="sourceGroup in orderedSourceGroup"
          :key="sourceGroup">
        <h2 class="report-section-title"><a :href="generateProjectLink(plagiarismReport.projectId)" >{{ sourceGroup }}</a></h2>
        <div v-for="reportSection in groupedPlagiarismChecks[sourceGroup]"
             :key="reportSection.id"
             class="report-section-container">
          <component :is="providerComponent(reportSection.provider)"
                     :data="reportSection"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.report {
  @apply py-8 px-16 bg-[#EFEFEF];
  &-summary {
    @apply flex text-white flex-col py-6 px-14 mb-6;
    &.review {
      @apply bg-red-500;
    }
    &.pass {
      @apply bg-green-400;
    }
    &-content {
      @apply flex justify-between my-16;
    }
    &-generated {
      @apply text-base;
    }
    &-title {
      &-report {
        @apply text-[3rem] font-medium;
      }
      &-project {
        @apply text-2xl py-6 underline leading-8;
      }
      &-project-teams {
        @apply text-lg;
      }
    }
    &-status {
      @apply ml-auto flex items-center flex-col justify-center min-w-[20rem];
      &-icon {
        @apply mb-4 text-[6rem];
      }
      &-text {
        @apply text-2xl;
      }
    }
    &-disclaimer {
      @apply mt-4;
      p {
        @apply text-base;
        a {
          @apply underline;
        }
      }
    }
  }
  &-header {
    @apply flex justify-between;
    &-title {
      @apply text-xl max-w-[70%] font-medium;
      p {
        @apply text-gray-500 font-light;
      }
    }
    &-timestamp {
      @apply text-right whitespace-nowrap;
    }
  }
  &-section {
    &-title {
      @apply text-[3rem] mb-8 underline capitalize font-[500];
    }
    &-container {
      @apply bg-white rounded-2xl border border-gray-300 p-4 mb-8;
    }
  }
}
// Hide the feedback widget
:global(#feedbacklabel) {
  display: none !important;
}
</style>

