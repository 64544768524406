import ProjectService from "@/services/project"
import { processStatuses } from "@/utils"
import FileService from '@/services/file'
import TimelineService from '@/services/timeline';

export default {
  state: () => ({
    projects: {},
    projects_errors: {},
    project_id: '',
    projectStatus: processStatuses.IDLE,
    projectMainEdit: false,
    projectUpdateStatus: processStatuses.IDLE,
    projectUpdateError: null,
    projectImage: null,
    projectImageUpdateStatus: processStatuses.IDLE,
    projectImageUpdateError: null,
    projectErrors: {},
    projectSubmissionDeadline: null,
    projectSubmissionDeadlineStatus: processStatuses.IDLE,
    projectSubmissionDeadlineError: null,
    projectSubmitError: null
  }),
  getters: {
    projects: state => Object.values(state.projects),
    projectsErrors: state => state.projectsErrors,
    projectsLoaded: state => state.projectStatus === processStatuses.LOADED,
    projectID: state => state.project_id,
    projectMainEdit: state => state.projectMainEdit,
    activeProject: state => state.projectStatus === processStatuses.LOADED && state.project_id > 0 ? state.projects[state.project_id] : null,
    projectIsValid: state => Object.keys(state.projectErrors).length === 0,
    projectSubmissionDeadline: state => state.projectSubmissionDeadline,
    projectSubmissionDeadlineStatus: state => state.projectSubmissionDeadlineStatus,
    projectSubmitError: state => state.projectSubmitError
  },
  mutations: {
    SET_PROJECTS (state, data) {
      state.projects = data
    },
    SAVE_PROJECTS (state, data) {
      state.projects = [...state.projects, data]
    },
    DELETE_PROJECT (state, id) {
      delete state.projects[id]
    },
    SET_PROJECTS_ERRORS (state, error) {
      state.projectsErrors[error.key] = error.errMsg
    },
    PROJECTS_LOADING (state) {
      state.projectStatus = processStatuses.LOADING
    },
    PROJECTS_LOADED (state, projects) {
      state.projects = projects.reduce((acc, project) => ({...acc, [project.id]: project}), {})
      state.projectStatus = processStatuses.LOADED
    },
    PROJECTS_LOAD_ERROR (state) {
      state.projectStatus = processStatuses.ERROR
    },
    SET_PROJECT_ID (state, id) {
      state.project_id = id
    },
    PROJECT_DELETING (state) {
      state.projectStatus = processStatuses.LOADING
    },
    PROJECT_DELETED (state, id) {
      state.projectStatus = processStatuses.LOADED
      state.projects = state.projects.filter(m => m.id !== id)
    },
    PROJECT_DELETE_ERROR (state, err) {
      state.projectStatus = processStatuses.ERROR
      state.error = err
    },
    PROJECT_CARD_VALIDATE_RESET (state) {
      state.projectsErrors = {}
    },
    SET_FILE_ERROR (state, error) {
      state.error = error
    },
    PROJECT_MAIN_EDIT (state) {
      state.projectMainEdit = !state.projectMainEdit
    },
    PROJECT_UPDATING (state) {
      state.projectUpdateStatus = processStatuses.LOADING
    },
    PROJECT_UPDATED (state, project) {
      state.projects[project.id] = project
      state.projectUpdateStatus = processStatuses.LOADED
    },
    PROJECT_UPDATE_ERROR (state, err) {
      state.projectUpdateStatus = processStatuses.ERROR
      state.projectUpdateError = err
    },
    PROJECT_IMAGE_UPDATING (state) {
      state.projectImageUpdateStatus = processStatuses.LOADING
    },
    PROJECT_IMAGE_UPDATED (state, img) {
      state.projectImage = img
      state.projectImageUpdateStatus = processStatuses.LOADED
    },
    PROJECT_IMAGE_UPDATE_ERROR (state, err) {
      state.projectImageUpdateStatus = processStatuses.ERROR
      state.projectImageUpdateError = err
    },
    PROJECT_SUBMISSION_DEADLINE_LOADING (state) {
      state.projectSubmissionDeadlineStatus = processStatuses.LOADING
    },
    PROJECT_SUBMISSION_DEADLINE_LOADED (state, payload) {
      state.projectSubmissionDeadlineStatus = processStatuses.LOADED
      state.projectSubmissionDeadline = payload
    },
    PROJECT_SUBMISSION_DEADLINE_ERROR (state, err) {
      state.projectSubmissionDeadlineStatus = processStatuses.ERROR
      state.projectSubmissionDeadline = err
    },
    PROJECT_SUBMIT_ERROR (state, err) {
      state.projectSubmitError = err
    }
  },
  actions: {
    getProjects ({commit}, isTemplate = true) {
      commit('PROJECTS_LOADING', true)
      // change to API
      return new Promise((resolve, reject) => {
        ProjectService.getProjects({pageSize: 10, nextPageToken: 0, include: "", isTemplate: isTemplate}).then((response) => {
          commit('PROJECTS_LOADED', response.data.projects)
          resolve()
        }).catch((err) => {
          console.error(err)
          commit('SET_PROJECTS_ERRORS', err)
          reject(err)
        })
      })
    },
    getProject ({commit}, projectId) {
      commit('PROJECTS_LOADING', true)
      // change to API
      return new Promise((resolve, reject) => {
        ProjectService.getProject({templateID: projectId, include: "", template: true}).then((response) => {
          commit('PROJECTS_LOADED', [response.data.project])
          resolve()
        }).catch((err) => {
          console.error(err)
          commit('PROJECTS_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    uploadProjectImage({commit}, data) {
      commit('PROJECT_IMAGE_UPDATING')
      return new Promise((resolve, reject) => {
        if (typeof data.imageUrl === 'object' && data.imageUrl !== null) {
          FileService.upload({file: data.imageUrl}).then((response) => {
            if (response.status === 200) {
              data.imageUrl = response.data.link
              commit('PROJECT_IMAGE_UPDATED', response.data.link)
              resolve(data)
            }
          }).catch(error => {
            commit('SET_FILE_ERROR', error)
            commit('PROJECT_IMAGE_UPDATE_ERROR', error)
            reject(error)
          })
        } else {
          resolve(data)
        }
      })
    },
    updateProject({commit}, data) {
      commit('PROJECT_UPDATING')
      return new Promise((resolve, reject) => {
        ProjectService.updateProject(data.projectId , data.response).then((response) => {
          commit('PROJECT_UPDATED', response.data.project)
          commit('PROJECT_MAIN_EDIT')
          resolve()
        }).catch((err) => {
          const payload = {'key': 'proErr', 'errMsg': err.data.message}
          commit('PROJECT_UPDATE_ERROR', payload)
          reject(err)
        })
      })
    },
    setProjectID ({ commit }, id) {
      commit('SET_PROJECT_ID', id)
    },
    deleteProject ({ commit }, id) {
      commit('PROJECT_DELETING')
      ProjectService.deleteProject({ projectId: id }).then(() => {
        commit('PROJECT_DELETED', id)
      }).catch(err => {
        commit('PROJECT_DELETE_ERROR', err)
      })
    },
    projectValidateReset({commit}) {
      commit('PROJECT_CARD_VALIDATE_RESET')
    },
    setProjectMainToggle({commit}) {
      commit('PROJECT_MAIN_EDIT')
    },
    projectValidate({commit}, formData) {
      commit('PROJECT_CARD_VALIDATE_RESET')
      let isValid = true
      Object.keys(formData).filter(item => {
        if (!formData[item]) {
          const formItemPayload = {
            'key': item,
            'errMsg': (item === 'imageUrl') ? 'Please select a ' + item : 'Please enter a ' + item
          }
          isValid = false
          commit('SET_PROJECTS_ERRORS', formItemPayload)
        }
      })
      return isValid
    },
    UpdateProjectStatuses ({commit}, projectData) {
      return new Promise((resolve, reject) => {
        ProjectService.UpdateProjectStatuses({ projectId: projectData.projectId, submissionStatus: projectData.submissionStatus, notes: projectData.notes, updateMask: projectData.updateMask }).then((response) => {
          // ToDo refactor: move Update project statuses to project-submissions module
          if (response.data.project?.projectStatus?.submissionStatus) {
            commit("SET_PROJECT_SUBMISSION_STATUS", {
              projectId: projectData.projectId,
              status: response.data.project.projectStatus.submissionStatus,
              notes: response.data.project.projectStatus.notes,
              notifiedAt: response.data.project.projectStatus.notifiedAt
            }, { root: true })
          } else {
            console.error('No submission status found in response')
          }
          resolve(response.data)
        }).catch((err) => {
          commit('PROJECT_SUBMIT_ERROR', err)
          reject()
        })
      })
    },
    fetchProjectSubmissionDeadline({commit, rootGetters}) {
      commit('PROJECT_SUBMISSION_DEADLINE_LOADING')

      return new Promise((resolve, reject) => {
        if (rootGetters.ebEventStatus !== processStatuses.LOADED) {
          reject()
        }

        TimelineService.getSubmissionDeadline(rootGetters.ebEventId).then((response) => {
          commit('PROJECT_SUBMISSION_DEADLINE_LOADED', response.data.timeline)
          resolve()
        }).catch((err) => {
          commit('PROJECT_SUBMISSION_DEADLINE_ERROR', err)
          reject()
        })
      })
    },
    deleteUserFromEventProjects({commit}, payload) {
      commit('PARTICIPANTS_DELETED_LOADING')
      return new Promise((resolve, reject) => {
        ProjectService.deleteUserFromEventProjects(payload).then(() => {
          commit('PARTICIPANTS_DELETED', payload)
          resolve()
        }).catch((err) => {
          commit('PARTICIPANTS_DELETED_ERROR', payload)
          reject(err)
        })
      })
    },
  }
}
