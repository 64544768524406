<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps(
  {
    data: Object
  }
)

const jsonResponse = computed(() => {
  if (!props.data.response) {
    return null
  }
  return JSON.parse(props.data.response)
})

const totalResults = computed(() => {
  return jsonResponse.value?.stats?.total_results
})

const matches = computed(() => {
  /* Format
  {
    "query_match_percent": 59.630001068115234,
    "image_url": "https://img.tineye.com/result/ca33455068c0e80276ed68c1849c00e16bb77abfb720f48dfe338ab674d0249e-79",
    "domain": "bulbapp.io",
    "score": 57.51599884033203,
    "width": 800,
    "height": 800,
    "size": 640000,
    "format": "PNG ",
    "filesize": 199757,
    "overlay": "overlay/de7de0f04f83b256ffd8ba5b859277052f996c46/ca33455068c0e80276ed68c1849c00e16bb77abfb720f48dfe338ab674d0249e?m11=0.9221410155296326\u0026m12=0.001762000028975308\u0026m13=0.04609160125255585\u0026m21=-0.001762000028975308\u0026m22=0.9221410155296326\u0026m23=3.3767600059509277",
    "tags": [],
    "backlinks": [
        {
            "url": "https://cdn.bulbapp.io/frontend/images/1a6f6d9f-8516-412a-ab6d-9f8c20c67157/1",
            "backlink": "https://www.bulbapp.io/p/b70ab567-fd08-4ebb-88c0-0e593aec0dd5/the-us-revealed-the-campaign-against-houthi-in-the-red-sea",
            "crawl_date": "2024-04-08"
        }
    ]
  },
   */
  return jsonResponse.value?.results?.matches
})

const showTitle = computed(() => {
  return props.data?.sourceLabel && props.data?.sourceLabel.includes('Attachment')
})
</script>

<template>
  <div>
    <div v-if="showTitle" class="summary-title">{{ data.sourceLabel }}</div>
    <div class="summary-container">
      <div class="summary-container-image">
        <img :src="data.source" class="h-auto object-contain" />
      </div>
      <div class="summary-container-description">
        <div class="flex items-center">
          <div class="mr-4 text-[2.5rem]">
            <i v-if="totalResults > 0" class="text-red-400 fa-solid fa-circle-exclamation"></i>
            <i v-else class="text-green-400 fa-solid fa-circle-check"></i>
          </div>
          <div>
            <p>{{ totalResults }} Matches</p>
            <p>Found across the internet</p>
          </div>
        </div>
      </div>
    </div>
    <div class="matches-container">
      <p v-if="matches && matches.length > 0" class="matches-container-title">Top Matches ({{ matches.length > 10 ? 10 : matches.length }})</p>
      <template v-for="match in matches" :key="match.id">
        <hr class="mb-2">
        <div class="matches-container-item">
          <div class="matches-container-item-description">
            <p class="domain">{{ match.domain }}</p>
            <a class="link" target="_blank" :href="match.backlinks[0].backlink">{{ match.backlinks[0].backlink }}</a>
            <p class="meta">{{ match.backlinks[0]?.crawl_date }} Match {{ parseFloat(match.query_match_percent).toFixed(2) }}%</p>
          </div>
          <div class="matches-container-item-image">
            <img :src="match.image_url" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.summary-title {
  @apply text-xl font-medium py-4;
}
.summary-container {
  @apply flex;
  &-image {
    @apply max-w-[200px] w-1/3;
    img {
      @apply w-full h-auto object-contain rounded-2xl border border-gray-300;
    }
  }
  &-description {
    @apply w-2/3 px-6 flex items-center;
  }
}
.matches-container {
  @apply pt-4;
  &-title {
    @apply text-xl font-medium py-4;
  }
  &-item{
    @apply flex items-center mb-2;
    &-description {
      @apply w-3/4 overflow-ellipsis truncate;
      p, a {
        @apply mb-1;
        &.domain, &.meta {
          @apply text-gray-500;
        }
        &.link {
          @apply font-medium;
        }
      }
    }
    &-image {
      @apply ml-auto;

      img {
        @apply max-h-[85px] object-contain rounded-2xl border border-gray-300;
      }
    }
  }
}
</style>
