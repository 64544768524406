<script setup>
// Required Packages
import { useRoute } from 'vue-router'
import { processStatuses, plagiarismStatuses, plagiarismLabels } from '@/utils'
import Loading from '@/components/Base/Loading'

const route = useRoute()

defineProps({
  report: {
    type: Object,
    required: true
  },
  status: {
    type: String,
    required: true,
    default: processStatuses.IDLE
  }
})
</script>

<template>
  <div v-if="!report">N/A</div>
  <div v-else-if="status === processStatuses.LOADED">
    <router-link v-if="report.status === plagiarismStatuses.COMPLETED"
                 target="_blank"
                 class="report-result"
                 :class="`report-result-${report.result.toLowerCase()}`"
                 :to="{ name: 'PlagiarismReport', params: { slug: route.params.slug, projectId: report.projectId } }">
      {{ plagiarismLabels(report) }}
    </router-link>
    <p v-else
       :class="`report-status-${report.status.toLowerCase()}`">
      {{ plagiarismLabels(report) }}
    </p>
  </div>
  <div v-else-if="status === processStatuses.LOADING">
    <loading />
  </div>
  <div v-else-if="status === processStatuses.ERROR">
    <p class="text-red-500">Error fetching plagiarism reports</p>
  </div>
</template>

<style lang="scss" scoped>
.report-status {
  &-completed {
    @apply text-green-500;
  }
  &-in_progress {
    @apply text-blacks-500;
  }
  &-pending {
    @apply text-greys-400;
  }
  &-failed {
    @apply text-red-500;
  }
}
.report-result {
  @apply text-green-500 underline;
  &-pass {
    @apply text-green-500;
  }
  &-review {
    @apply text-red-500;
  }
}
</style>
