// Update events.eventSettings.privacy field
// 0 - Public
// 1 - Private

const PUBLIC = 'PUBLIC'
const PRIVATE = 'PRIVATE'

const PrivacySettings = [PUBLIC, PRIVATE]

export const eventPrivacySettings = {
    PUBLIC,
    PRIVATE,
    PrivacySettings
}
