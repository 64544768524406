<script setup>
// Required Packages
import { computed, onMounted, ref, watch } from 'vue'
import store from '@/store'
// Custom Component
import 'vue3-easy-data-table/dist/style.css'
import ListCard from '@/components/Submissions/ListCard'
import { submissionStatusType } from '@/utils/submission-status'
import DashboardTable from '@/components/Base/Table/Dashboard'
import { processStatuses, generateProjectLink } from '@/utils'
import Loading from '@/components/Base/Loading'
import PlagiarismStatus from '@/components/PlagiarismScans/PlagiarismStatus'

const selectColumnId = ref()

const headers = [
  { text: "project", value: "projectLink", sortable: true },
  { text: "team", value: "team", searchable: true },
  { text: "similarity scan", value: "plagiarism", sortable: true}
]

const eligibleProjectsCountCard = computed(() => {
  return {
    status: submissionStatusType.PLAGIARISM,
    text: 'plagiarism',
    number: eligibleProjectsCount.value
  }
})

const eligibleProjectsCount = computed(() => {
  return store.getters.getPlagiarismReportsCount || 0
})

const plagiarismReportStatus = computed(() => {
  return store.getters.getPlagiarismReportStatus
})

const plagiarismReports = computed(() => {
  return store.getters.getPlagiarismReports
})

const eventId = computed(() => {
  return store.getters.ebEventId
})

const fetchPlagiarismReports = async () => {
  await store.dispatch('fetchPlagiarismReports')
}

watch([eventId], () => { fetchPlagiarismReports() }, { deep: true })

/*
const eventIsPublished = computed(() => {
  return store.getters.eventSettings.status === 'Published'
})
*/

onMounted(async () => {
  await fetchPlagiarismReports()
})

const sort = (sortBy = 'title') => {
  store.dispatch('setPlagiarismReportsSortBy', sortBy)
}

const membershipsToTeamNames = (memberships) => {
  return memberships.map(membership => {
    if (membership.user.firstName.trim() && membership.user.lastName.trim()) {
      return membership.user.firstName + ' ' + membership.user.lastName
    }
    return membership.user.userName
  }).join(', ')
}
</script>

<template>
  <div class="plist"
       :class="{'plist-withProject' : eligibleProjectsCount !== 0}">
    <ul class="plist-list">
      <list-card :project="eligibleProjectsCountCard"
                 :is-loading="plagiarismReportStatus === processStatuses.LOADING"
                 loading-style="pulse"
                 class="active" />
    </ul>
    <div v-if="plagiarismReportStatus === processStatuses.LOADING"
         class="flex align-middle justify-center items-center mt-8">
      <loading />
    </div>
    <template v-else-if="plagiarismReportStatus === processStatuses.LOADED">
      <div v-if="eligibleProjectsCount === 0"
            class="plist-noProject">
        <img src=" /images/icons/scan.png"
             alt="scan"
             class="w-32 h-32"/>
        <div class="flex flex-col" >
          <h2 class="font-medium text-h2 -mb-4 mt-4">
            There are no projects to scan yet
          </h2>
          <p class="plist-noProject_text">
            Once projects are submitted, they will appear here, <br/>
            and will be scanned for similarities.
          </p>
        </div>
      </div>
      <dashboard-table v-else
                       :sort-function="sort"
                       :headers="headers"
                       :select-id="selectColumnId"
                       :items="plagiarismReports">
        <template #content-column="{ item }">
          <td class="list-table-spacing">
            <a target="_blank"
               :href="generateProjectLink(item.project.id)"
               class="project-link">
              {{ item.project.title }}
            </a>

          </td>
          <td class="list-table-spacing">
            <div class="name-wrapper">
                {{membershipsToTeamNames(item.project.memberships)}}
            </div>
          </td>
          <td>
            <plagiarism-status :status="plagiarismReportStatus"
                               :report="item" />
          </td>
        </template>
      </dashboard-table>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.list {
  &-table {
    &-status {
      @apply min-w-[9rem];
    }
    &-spacing {
      @apply pr-3;
      &-btn {
        @apply font-light underline;
      }
    }
  }
}
:deep .vue3-easy-data-table__header {
  @apply z-[1];
}
:deep .vue3-easy-data-table__main {
  @apply overflow-visible;
}
.plist {
  @apply  my-6 max-w-[83.5rem] p-4 flex flex-col h-fit;
  &-list {
    @apply w-full flex gap-5 flex-wrap py-6 justify-start;
  }
  &-noProject {
    @apply flex flex-col py-4 w-full justify-center items-center h-full border bg-gray-50 border-greys-100 rounded-xl md:max-w-[46rem];
    &_text {
      @apply py-6 text-center max-w-[24.125rem] text-p-md;
    }
  }
  &-withProject {
    @apply border-none bg-transparent p-0;
  }
}
.name-wrapper {
  @apply flex flex-row items-center max-w-[24rem];
}
.avatar {
  @apply inline-block w-[2rem] h-[2rem] rounded-full object-cover;
}
.avatar-wrapper {
  @apply flex justify-items-center items-center shrink-0 mr-1;
}
.project-link {
  @apply text-product-400 underline;
}
.page-button {
  @apply px-2 py-[3px] mx-1 bg-greys-100 border rounded-md border-greys-200;
  &:disabled {
    @apply bg-greys-50 text-greys-400 cursor-not-allowed
  }
}
.report-status {
  &-completed {
    @apply text-green-500;
  }
  &-in_progress {
    @apply text-yellow-500;
  }
  &-pending {
    @apply text-greys-400;
  }
  &-failed {
    @apply text-red-500;
  }
}
.report-result {
  &-pass, &- {
    @apply text-green-500;
  }
  &-review {
    @apply text-red-500;
  }
}
</style>
