/*
  PENDING = 0;
  IN_PROGRESS = 1;
  COMPLETED = 2;
  FAILED = 3;
  SKIPPED = 4;
 */

const PENDING = 'PENDING'
const IN_PROGRESS = 'IN_PROGRESS'
const COMPLETED = 'COMPLETED'
const FAILED = 'FAILED'
const SKIPPED = 'SKIPPED'

const PlagiarismStatuses = [PENDING, IN_PROGRESS, COMPLETED, FAILED, SKIPPED]

export const plagiarismStatuses = {
    PENDING,
    IN_PROGRESS,
    COMPLETED,
    FAILED,
    SKIPPED,
    PlagiarismStatus: PlagiarismStatuses
}

export const plagiarismLabels = ({ status, result } = { status: "N/A", result: "" }) => {
    // When it's completed show the results
    if (status === plagiarismStatuses.COMPLETED) {
        switch (result.toLowerCase()) {
            case 'review':
                return 'Need Review'
            case 'pass':
                return 'Pass'
            default:
                return 'Pass'
        }
    }

    // Otherwise show the status
    switch (status) {
        case plagiarismStatuses.IN_PROGRESS:
            return 'Processing...'
        case plagiarismStatuses.PENDING:
            return 'pending'
        case plagiarismStatuses.FAILED:
            return 'failed'
        default:
            return 'N/A'
    }
}
