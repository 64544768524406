<script setup>
import { computed, ref } from 'vue'
import BaseTag from '@/components/Base/Tags'
import BaseButton from '@/components/Base/Button/BaseButton'
import Loader from '@/components/Base/Loader'
import store from '@/store'
import { submissionStatusType } from '@/utils/submission-status'

const props = defineProps({
  id: String,
  name: String,
  ariaLabel: String,
  projectId: {
    type: Number,
    required: true
  },
  eventId: {
    type: Number,
    required: true
  },
  text: {
    type: String,
    required: true
  }
})

const showStatusDropDown = ref(false)
const allStatusOptions = [submissionStatusType.APPROVED, submissionStatusType.DECLINED]
const currentStatus = ref(props.text || '')
const activeTag = ref('')
const showConfirmationModal = ref(false)
const loading = ref(false)

const statusDraft = computed(() => {
  return ![submissionStatusType.SUBMITTED, submissionStatusType.APPROVED, submissionStatusType.DECLINED].includes(currentStatus.value)
})

const tagSelected = computed(() => {
  return activeTag.value !== ''
})

const toggleStatusDropdown = () => {
  if (currentStatus.value !== submissionStatusType.DRAFT)
    showStatusDropDown.value = !showStatusDropDown.value
  activeTag.value = ''
}

const getActiveTagClass = (option) => {
  if (activeTag.value === option) {
    return 'active-tag'
  }
}

const statusOptions = (currentStatus) => {
  return allStatusOptions.filter(function(value) {
    return value !== currentStatus
  })
}

const convertText = (text) => {
  switch(text) {
  case submissionStatusType.SUBMITTED:
    return 'submitted'
  case submissionStatusType.APPROVED:
    return 'approved'
  case submissionStatusType.DECLINED:
    return 'declined'
  default:
    return 'draft'
  }
}

const selectOption = (option) => {
  activeTag.value = option
}

const tryUpdateStatus = () => {
  if (activeTag.value === '') {
    return
  }
  else if (currentStatus.value === submissionStatusType.SUBMITTED) {
    showDeclineReasonModalOrUpdateStatus()
  } else {
    showConfirmationModal.value = true
  }
}

const showDeclineReasonModalOrUpdateStatus = () => {
  if (activeTag.value === submissionStatusType.DECLINED) {
    showConfirmationModal.value = false
    store.dispatch('ToggleProjectDeclineReasonModal', {
      confirmAction: async () => {
        await store.dispatch('ResetModal')
        updateStatus()
      },
      cancelAction: async () => {
        await store.dispatch('ResetModal')
      },
      projectId: props.projectId
    })
  } else {
    updateStatus()
  }
}

const updateStatus = () => {
  loading.value = true
  showConfirmationModal.value = false
  const projectData = {
    projectId: props.projectId,
    submissionStatus: activeTag.value
  }
  store.dispatch('UpdateProjectStatuses', projectData).then((response) => {
    showStatusDropDown.value = false
    currentStatus.value = response.project.submissionStatus
    store.dispatch('getProjectSubmissionsCounts', { eventId: props.eventId }).then(() => {
      loading.value = false
      // store.dispatch('getProjectSubmissions', { eventId: props.eventId })
    }).catch(error => {
      console.error(error)
    })
  }).catch(error => {
    console.error(error)
  })
}

const closeConfirmationModal = () => {
  showConfirmationModal.value = false
}
</script>

<template>
  <div>
    <div v-if="showStatusDropDown" class="status-dropdown-overlay" @click.stop="toggleStatusDropdown"></div>
    <div v-if="showConfirmationModal" class="modal-overlay"></div>
    <div v-if="showConfirmationModal"
         class="modal modal-base">
      <i class="fa-solid fa-xmark fa-xl modal-icon"
         @click.prevent="closeConfirmationModal" />
      <div class="modal-header">
        <h3 class="modal-header font-ceraProMedium header-three">
          Update project status again?
        </h3>
      </div>
      <p class="modal-content paragraph-sm">
        You are about to update a project status that has already been approved/declined.<br><br>Are you sure you would like to proceed?
      </p>
      <div class="modal-footer">
        <div class="modal-footer">
          <base-button :functions="closeConfirmationModal"
                       class-name="modal-footer-btn"
                       btn-type="secondary"
                       text="Cancel" />
          <base-button :functions="showDeclineReasonModalOrUpdateStatus"
                       class-name="modal-footer-btn"
                       text="Yes, update status" />
        </div>
      </div>
    </div>
    <div v-if="loading" class="loader-container">
      <loader></loader>
    </div>
    <div v-if="!loading" class="status-dropdown-container" :class="{'remove-border' : showStatusDropDown || statusDraft}">
      <div v-if="showStatusDropDown" class="status-dropdown-container-active">
        <div class="status-dropdown-button" @click.stop="toggleStatusDropdown">
          <base-tag :text="convertText(currentStatus)"
                    class="selected-tag"
                    @click="selectOption(currentStatus)">
          </base-tag>
          <i class="fa-solid fa-chevron-up ml-3"></i>
        </div>
        <div class="status-dropdown-options" >
          <base-tag v-for="(option, index) in statusOptions(currentStatus)"
                    :key="index"
                    :text="convertText(option)"
                    :class="getActiveTagClass(option)"
                    class="option-tag"
                    @click="selectOption(option)">
          </base-tag>
        </div>
        <div class="confirm-button font-ceraProMedium" :class="{ 'button-active' : tagSelected }" @click="tryUpdateStatus">
          <p class="text-white">CONFIRM</p>
        </div>
      </div>
      <div class="status-dropdown-button" :class="{ 'draft-status' : statusDraft }" @click.stop="toggleStatusDropdown">
        <base-tag :text="convertText(currentStatus)"
                  class="selected-tag"
                  @click="selectOption(currentStatus)">
        </base-tag>
        <i v-if="!statusDraft" class="fa-solid fa-chevron-down ml-3"></i>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tag {
  @apply flex flex-row items-center justify-start max-w-[10rem] px-2 py-1 rounded-lg tracking-wider uppercase text-blacks-500;
  &-dot {
    @apply w-2 h-2 rounded-full mr-1;
  }
}

.status-dropdown {
  &-overlay {
    @apply fixed top-0 left-0 z-10 w-[100vw] h-[100vh];
  }
  &-button {
    @apply cursor-pointer bg-white flex flex-row items-center bg-transparent;
    &.draft-status {
      @apply cursor-default;
    }
  }
  &-options {
    @apply bg-white pt-[0.75rem] w-[6.25rem];
  }
  &-container {
    @apply relative w-[9.5rem] bg-white flex flex-col rounded-lg border-gray-200 border-[1px] my-3 py-[0.5rem] px-[1rem];
    &.remove-border {
      @apply border-transparent bg-transparent;
    }
    &-active {
      @apply absolute z-10 top-[-1px] left-[-1px] w-[9.5rem] bg-white flex flex-col rounded-lg border-gray-200 border-[1px] py-[0.5rem] px-[1rem];
    }
  }
}

.option-tag {
  @apply border-white border-[1.5px] cursor-pointer mb-[0.75rem];
  &.active-tag {
    @apply border-black drop-shadow-2xl;
  }
}

.selected-tag {
  @apply mb-0
}

.confirm-button {
  @apply rounded-lg cursor-default bg-gray-300 px-3 py-[0.3rem] my-auto text-blacks-500 w-[fit-content];
  &.button-active {
    @apply bg-purple-600 cursor-pointer;
  }
}

//.approved {
//  @apply bg-other-green/[0.2];
//  &-dot {
//    @apply bg-other-green;
//  }
//}
//
//.draft {
//  @apply bg-greys-300/[0.2];
//  &-dot {
//    @apply bg-greys-300;
//  }
//}
//
//.isActive {
//  @apply bg-greys-300/[0.2];
//  &-dot {
//    @apply bg-other-green;
//  }
//}
//
//.declined {
//  @apply bg-other-red/[0.2];
//  &-dot {
//    @apply bg-other-red;
//  }
//}
//
//.submitted {
//  @apply bg-other-blue/[0.2];
//  &-dot {
//    @apply bg-other-blue;
//  }
//}

.loader-container {
  @apply flex justify-center w-[10rem] bg-white rounded-lg border-gray-200 border-[1px] py-[0.75rem]
}

// Confirmation Modal
.modal-overlay {
  @apply fixed top-0 left-0 z-[11] bg-black/30 w-[100vw] h-[100vh]
}

.modal {
  @apply fixed top-[20%] left-[35%] z-[11] flex flex-col border border-2 rounded-xl shadow px-8 py-[4rem] justify-center border-greys-100 bg-white;
  &-icon {
    @apply absolute right-3 top-6 text-greys-300 cursor-pointer;
    &:hover {
      @apply text-product-400;
    }
  }
  &-base {
    @apply max-w-[35rem];
  }
  &-header {
    @apply text-xl text-center;
  }
  &-content {
    @apply text-lg text-center pt-10 pb-12 text-greys-300;
  }
  &-footer {
    @apply text-base flex flex-row justify-around w-full;
    &-btn {
      @apply min-w-[12rem] py-3;
    }
  }
}
</style>
