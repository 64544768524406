<script setup>
import { computed } from 'vue';

const props = defineProps(
  {
    data: Object
  }
)

const jsonResponse = computed(() => {
  if (!props.data.response) {
    return null
  }
  return JSON.parse(props.data.response)
})

const textHighlightHtml = computed(() => {
  return jsonResponse.value?.html
})

const totalPlagiarismScore = computed(() => {
  return jsonResponse.value?.plagiarism
})

const plagiarismRisk = (plagiarismNumber) => {
  const score = parseFloat(plagiarismNumber)
  if (!score) return 'low'

  if (score < 10) {
    return 'lower'
  } else if (score < 30) {
    return 'medium'
  } else {
    return 'high'
  }
}

const plagiarismSources = computed(() => {
  return jsonResponse.value?.sources
})

const showTitle = computed(() => {
  return props.data?.sourceLabel && props.data?.sourceLabel.includes('Attachment')
})
</script>

<template>
  <div>
    <div v-if="showTitle" class="plagiarism-title">{{data.sourceLabel}}</div>
    <div class="plagiarism-search-html" v-html="textHighlightHtml"></div>
    <div class="plagiarism-result">
      <h2 class="text-xl">Results</h2>
      <div class="r-meter">
        <div class="r-meter-wrap">
          <div class="r-meter-value"
               :class="plagiarismRisk(totalPlagiarismScore)">
            <b>{{totalPlagiarismScore}}%</b>
          </div>
          <div class="r-meter-label">Similarity Score</div>
          <div class="r-meter-line">
            <div style="width: 100%"
                 :class="plagiarismRisk(totalPlagiarismScore)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="plagiarism-sources">
      <h2 v-if="plagiarismSources && plagiarismSources.length > 0" class="text-xl">Top Matches ({{plagiarismSources.length > 10 ? 10 : plagiarismSources.length }})</h2>
      <div class="sources">
        <div v-for="(source, index) in plagiarismSources.slice(0, 10)" :key="source.id" class="item">
          <div class="left">
            <div class="index">
              <span>{{index+1}}</span>
            </div>
            <div class="title">
              {{source.title}}
              <div class="item-source">
                <a :href="source.url" target="_blank">{{source.url}}</a>
              </div>
            </div>
          </div>
          <div class="right">
            <span class="plagiarism">
              {{parseFloat(source.plagiarism).toFixed(2)}} %
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.plagiarism-title {
  @apply text-xl font-medium py-4;
}
.plagiarism-result {
  @apply flex flex-col mt-4;
  .r-meter {
    @apply py-4;
    &-wrap {
      max-width: 320px;
    }
    &-value {
      float: right;
      font-size: 14px;
      &.low {
        color: #00a86b;
      }
      &.medium {
        color: #f7b500;
      }
      &.high {
        color: #ff6f58;
      }
    }
    &-label {
      font-size: 13px;
      font-weight: 600;
    }
    &-line {
      height: 6px;
      border-radius: 6px;
      background: #f2f2f4;
      margin: 6px 0;
      div {
        height: 6px;
        border-radius: 6px;
        &.low {
          background: #00a86b;
        }
        &.medium {
          background: #f7b500;
        }
        &.high {
          background: #ff6f58;
        }
      }
    }
  }
}
.plagiarism-search-html {
  @apply my-4;
  :deep {
    .rb-r {
      border-bottom: 2px solid #f7745d;
      background: #ffded9;
    }
    .rb-y {
      border-bottom: 2px solid #f3c64c;
      background: #fff4be;
    }
    .rp {
      @apply mb-4
    }
    .sources {
      @apply hidden;
    }
    .item {
      @apply flex flex-col border-b-2 border-gray-300 pb-4 pt-2;
      &-container {
        @apply flex items-center justify-between;
        .clear {
          @apply hidden;
        }
        .right {
          .plagiarism {
            @apply inline-block rounded px-2 py-1 bg-[#fff5f3] text-[#ff6f58] font-bold;
          }
        }
      }
    }
  }
}

.plagiarism-sources {
  @apply mt-4;
  .item {
    @apply flex border-b-2 border-gray-300 pb-4 pt-2 items-center justify-between;
    .left {
      @apply w-4/5 flex;
      .index {
        @apply ml-2 mr-4 flex items-center;
        span {
          @apply bg-[#ff6f58] text-white rounded-full w-6 h-6 flex items-center justify-center;
        }
      }
      .title {
        @apply flex flex-col max-w-[100%];
      }
    }
    .clear {
      @apply hidden;
    }
    .right {
      .plagiarism {
        @apply inline-block rounded px-2 py-1 bg-[#fff5f3] text-[#ff6f58] font-bold;
      }
    }
    &-source {
      @apply text-sm overflow-ellipsis truncate;
      a {
        @apply text-blue-500;
      }
    }
  }
}
</style>
