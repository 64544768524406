<script setup>
// Required Packages
import { onMounted, computed, onUnmounted } from 'vue'
import ModalLayout from '@/components/Modals/ModalBase'
import BaseButton from '@/components/Base/Button/BaseButton'
import VSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
import store from '@/store'
import { findUserSearchBy, userRoles, processStatuses, roleToEnum } from '@/utils'
import { organizationFeatureCodes } from '@/utils/organizationFeatures'

const organization = computed(() => {
  return store.getters.newOrganization
})

const searchUserResults = computed(() => {
  return store.getters.getSearchUserResults
})

const selectedTeamsToInvite = computed(() => {
  return store.getters.getSelectedTeamsToInvite
})

const invitedUsers = computed(() => {
  return store.getters.getInvitedUsers
})

const users = computed(() => {
  return store.getters.combinedUsers
})

const searchUser = (search, loading) => {
  // When user input is empty, don't search
  if (!search || search.length < 1) {
    return
  }
  loading(true)
  store.dispatch('searchUsers', {
    pageSize: 100,
    nextPageToken: 0,
    filterBy: findUserSearchBy.KEYWORD,
    keyword: search }).then(() => {
    }).finally(() => {
      loading(false)
    })
}


const isTeamSelectable = (option) => {
  const organizationFeatureData = store.getters.getOrganizationFeature(organizationFeatureCodes.NUMBER_OF_ADMIN_SEATS)
  const maxAdminSeats = organizationFeatureData?.value || 0

  const totalInvites = (selectedTeamsToInvite?.value?.length || 0) + (invitedUsers?.value?.length || 0)
  // is user type in a non-existing user, allow it only if it's a valid email
  if (option.isNew === true) {
    // validate option.email is a valid email
    const emailRegex = /\S+@\S+\.\S+/
    return !users?.value?.some(u => u.email === option.email) &&
      emailRegex.test(option.email) && (totalInvites < maxAdminSeats - 1)
  } else {
    // Existing user allow only if it's not in the current team
    return !invitedUsers?.value?.some(m => m.userId === option.id) &&
      !users?.value?.some(u => u.id === option.id) && (totalInvites < maxAdminSeats - 1)
  }
}

const createUserFromSearchText = (search) => {
  return {
    userName: search.trim(), //For displaying purpose
    email: search.trim(),
    isNew: true
  }
}

const filterResult = (options, search) => {
  return options.filter(option => {
    return option.userName.toLowerCase().includes(search.trim().toLowerCase()) ||
      option.firstName.toLowerCase().includes(search.trim().toLowerCase()) ||
      option.lastName.toLowerCase().includes(search.trim().toLowerCase()) ||
      option.email.toLowerCase().includes(search.trim().toLowerCase())
  })
}

const userSelected = (selectedOption) => {
  store.dispatch('resetSelectedTeamsToInvite')
  selectedOption.forEach((option) => {
    store.dispatch('selectUserToInvite', {user: option, role: "Admin" })
  })
}

const userDeselected = (deselectedOption) => {
  store.dispatch('deselectUserToInvite', {user: deselectedOption})
}

const clearResultIfEmpty = (search) => {
  if (!search.data) {
    store.dispatch('resetSearchResults')
  }
}

const resetOption = () => {
  store.dispatch('resetSearchResults')
}

const sendInvitations = (teams) => {
  const payload = {
    userInvitations: teams.map((team) => ({
      emailOrUsername: team.isNew ? team.email : team.userName,
      inviteRole: roleToEnum[userRoles.SS_EVENT_ADMIN]
    })),
    organizationId: organization.value?.id,
  }

  store.dispatch('inviteUsers', payload).then(() => {
    store.dispatch('resetSelectedTeamsToInvite')
    const fetchPayload = {
      organizationId: organization.value?.id,
      pageSize: 100,
      nextPageToken: 0,
    }

    store.dispatch('fetchExistingInvitedUsers', fetchPayload)
  })
}

const inviteAdminTeam = () => {
  const filteredSelectedTeams = selectedTeamsToInvite.value.filter((team) => {
    // Check if the team's email is not present in invitedUsers
    return !invitedUsers.value.some((user) => user.email === team.email)
  })

  sendInvitations(filteredSelectedTeams)
}

const deleteInviteUser = (id) => {
  store.dispatch('deleteInviteUser', { id: id })
}

const toggleInviteAdminPreview = () => {
  store.dispatch('ToggleModalInviteAdmin')
  store.dispatch('ToggleInviteAdminPreview')
}

const isInvitingAdmin = computed(() => {
  return store.getters.getInvitedUserStatus === processStatuses.LOADING
})

const primaryName = (user) => {
  // Determine if the user is new
  const isNewUser = !user?.firstName && !user?.lastName && !user.password

  // If the user is new, show the email
  if (isNewUser) {
    return user.email
  }

  const trimmedFirstName = user?.firstName ? user?.firstName.trim() : ''
  const trimmedLastName = user?.lastName ? user?.lastName.trim() : ''

  // Return the primary name based on available information
  return trimmedFirstName && trimmedLastName
    ? trimmedFirstName + ' ' + trimmedLastName
    : user?.userName
      ? user?.userName
      : user?.email
}

const secondaryName = (user) => {
  // Determine if the user is new
  const isNewUser = !user?.firstName && !user?.lastName && !user.password
  // If the user is new, show the email directly
  if (isNewUser) {
    return user.email
  }

  // Check the primary name
  const primary = primaryName(user)

  // If the primary name is the userName, return the email as the secondary name
  return primary === user?.userName ? user?.email : user?.userName
}

onMounted(() => {
  //fetch invited user list
  let payload = {
    organizationId: organization.value?.id,
    pageSize: 100,
    nextPageToken: 0,
  }
  store.dispatch('fetchExistingInvitedUsers', payload)
})

onUnmounted(() => {
  if (!store.getters.modal) {  // If modal is closed, reset the selected participants
    store.dispatch('resetSelectedTeamsToInvite')
  }
})
</script>

<template>
  <modal-layout modal-class="modal-invite">
    <template #modal-title>
      <h4 class="modal-invite-title">
        Invite Admins To Join Your Organization
      </h4>
    </template>
    <template #modal-content>
      <span class="modal-invite-content">
        <v-select
            v-model="selectedTeamsToInvite"
            taggable
            multiple
            label="userName"
            :filter="filterResult"
            index="id"
            placeholder="Email or username, comma seperated"
            :options="searchUserResults"
            class="modal-invite-team-search"
            :selectable="isTeamSelectable"
            :create-option="createUserFromSearchText"
            @input="clearResultIfEmpty"
            @option:selected="userSelected"
            @option:deselected="userDeselected"
            @close="resetOption"
            @search="searchUser">
            <!-- :dropdown-should-open="() => true"&ndash;&gt; -->
          <template #option="{ avatar, userName, firstName, lastName }">
            <span class="modal-invite-team-search-result-container">
              <span class="modal-invite-team-search-result-container-avatar">
                <avatar :image="avatar"/>
              </span>
              <span class="modal-invite-team-search-result-container-label">{{ firstName }} {{ lastName }} ({{ userName}})</span>
            </span>
          </template>
          <template #no-options>
            Please enter email or username
          </template>
          <template #open-indicator>
            <!-- Disable dropdown icon -->
            <span></span>
          </template>
        </v-select>
      </span>
      <div class="modal-invite-members">
        <h6 v-if="invitedUsers.length > 0" class="modal-invite-invited">
          Already Invited
        </h6>
        <ul class="modal-invite-invitees">
          <div v-for="invitedData in invitedUsers"
                     :key="invitedData.id">
            <span class="modal-invite-invitee">
              <span class="flex flex-col">
                <span class="modal-invite-invitee-primary">
                  {{primaryName(invitedData.user)}}
                </span>
                <span class="modal-invite-invitee-secondary">
                  {{secondaryName(invitedData.user)}}
                </span>
              </span>
              <span v-if="invitedData.acceptedAt === null" class="modal-invite-invitee-delete" @click="deleteInviteUser(invitedData.id)">
                <i class="fa-regular fa-trash-can"></i>
              </span>
            </span>
          </div>
        </ul>
      </div>
      <hr class="modal-invite-underline"/>
    </template>
    <template #modal-button>
      <span class="modal-invite-btn-container">
        <base-button :functions="toggleInviteAdminPreview"
                     class-name="modal-invite-btn modal-invite-btn-back"
                     btn-type="text">
          <template #contents>
            <span class="capitalize font-light">
              <i class="fas fa-eye"></i>
              See Email Preview
            </span>
          </template>
        </base-button>
        <base-button :functions="inviteAdminTeam"
                      :disabled="selectedTeamsToInvite.length === 0 || isInvitingAdmin"
                      :is-loading="isInvitingAdmin"
                      class-name="modal-invite-btn"
                      text="">
          <template #contents>
            <span class="capitalize font-light">
              Send Invite
            </span>
          </template>
        </base-button>
      </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.modal {
  &-invite {
    @apply md:h-auto max-w-[29rem] md:p-7 p-4 rounded-b-none md:rounded-2xl bg-white;
    &-content {
      @apply text-blacks-300 text-p-md text-center;
    }
    &-members {
      @apply pr-10 md:min-h-[10rem] md:max-h-[30rem] overflow-y-auto;
    }
    &-title {
      @apply text-h4 font-medium text-blacks-500 text-center pt-5 pb-6;
    }
    &-subTitle {
      @apply text-p-md text-blacks-300 text-center px-7;
    }
    &-invited {
      @apply text-p-xs capitalize text-blacks-500 pt-5 pb-2 font-semibold;
    }
    &-invitees {
      @apply flex flex-col pb-4;
    }
    &-invitee {
      @apply flex flex-row py-2 items-center justify-between;
    }
    &-underline {
      @apply text-greys-300 w-[100%] h-0.5;
    }
    &-btn {
      @apply w-auto h-[2.5rem] mx-2 normal-case p-4;
      &-container {
        @apply flex flex-row justify-center mt-8 ml-4;
      }
    }
    &-invitee {
      @apply w-full md:w-[24rem] overflow-x-hidden;
      &-name {
        @apply w-1/3;
      }
      &-primary {
        @apply font-medium;
      }
      &-secondary {
        @apply font-light text-p-sm;
      }
      &-delete {
        @apply cursor-pointer w-1/3 text-end;
      }
    }
    &-team-search {
      @apply w-full mr-4;
      &-result-container {
        @apply flex flex-row items-center;
        &-label {
          @apply pl-2 h-[1rem];
        }
      }
      :deep(.vs__dropdown-toggle) {
        @apply bg-white font-light p-2 w-full text-center border rounded-md focus:outline-primary focus:outline focus:outline-2;
      }
      :deep(.vs__search) {
        @apply bg-white;
      }
      :deep(.vs__search::placeholder){
        @apply text-gray-400;
      }
      :deep(.vs__dropdown-menu) {
        @apply accent-other-red max-h-[10rem];
      }
    }
  }
}
</style>
