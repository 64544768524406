<script setup>
// Required Components
import { computed, onMounted, watch, onUnmounted } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
// Store
import store from '@/store'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'
// Custom Components
import EventPageBase from '@/pages/EventBuilder/EventPageBase'
import CardEventBuilder from '@/components/Base/Cards/EventBuilder'
import CreateTable from '@/components/Sponsors/CreateTable'
import { bitwiseOperators, processStatuses } from '@/utils'
import { eventFeatureCodes } from '@/utils/eventFeatures'
import { v4 as uuidv4 } from 'uuid'
import { eventCompletionValues } from '@/utils'

const router = useRouter()

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const eventStatus = computed(() => {
  return store.getters.ebEventStatus
})

const sponsorFeatureEnabled = computed(() => {
  const feature = store.getters.getEventFeature(eventFeatureCodes.ENABLE_SPONSOR)
  return feature?.value === 1
})

const sponsorList = computed(() => {
  return store.getters.sponsors
})

const sponsorListStatus = computed(() => {
  return store.getters.sponsorsStatus
})

const isSectionReady = computed(() => {
  if (store.getters.ebEventStatus !== processStatuses.LOADED) {
    return false
  } else {
    if (sponsorFeatureEnabled.value) {
      return store.getters.sponsorsStatus === processStatuses.LOADED
    } else {
      return true
    }
  }
})

const sponsorImageUploadingStatus = computed(() => {
  return store.getters.sponsorImageUploadStatus
})

const uploadButtonIsLoading = computed(() => {
  return [processStatuses.SAVING, processStatuses.LOADING].includes(sponsorListStatus.value) || sponsorImageUploadingStatus.value === processStatuses.SAVING
})

const uploadButtonIsDisabled = computed(() => {
  return uploadButtonIsLoading.value || !store.getters.isSponsorsUpdated || eventStatus.value !== processStatuses.LOADED
})

const prev = () => {
  router.push({ name: 'Categories', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

const addSponsors = () => {
  store.dispatch('addEventSponsor', {
    id: uuidv4(),
    name: '',
    image: ''
  })
}

onMounted(() => {
  // Fetch sponsors only when sponsor feature is enabled
  if (sponsorFeatureEnabled.value) {
    store.dispatch('fetchEventSponsors')
  }
  addEventListener("beforeunload", beforeUnloadHandler)
})

watch(() => store.getters.ebEventStatus, (status) => {
  if (status === processStatuses.LOADED && sponsorListStatus.value === processStatuses.IDLE && sponsorFeatureEnabled.value) {
    store.dispatch('fetchEventSponsors')
  }
})

const saveSponsors = () => {
  const invalidSponsors = sponsorList.value.filter(sponsor => !sponsor.image)
  if (invalidSponsors.length > 0) {
    store.dispatch('setSponsorsErrorList', invalidSponsors)
    store.dispatch('triggerToast', { message: 'Sponsors must have logo specified.', type: 'error' })
    return
  }
  store.dispatch('uploadSponsorImages').then(() => {
    store.dispatch('saveEventSponsors').then(() => {
      store.dispatch('triggerToast',{ message: 'Sponsors have been updated', type: 'success'})
      let operation = ''
      if (sponsorList.value?.length === 0) {
        operation = bitwiseOperators.REMOVE
      } else {
        operation = bitwiseOperators.COMBINE
      }
      store.dispatch('updateEventConfigs', {
        eventId: event.value.id,
        configKey: "event_config_completion",
        configValue: eventCompletionValues.event_config_completion_sponsors.value,
        isConfigValueBitMask: true, bitwiseOperation: operation
      }).then(() => {
        store.dispatch('getEventConfigs', { eventId: event.value.id })
      }).catch((err) => {
        console.debug(err)
      })
    }).catch(() => {
      store.dispatch('triggerToast',{ message: 'Failed to update sponsors', type: 'error'})
    })
  }).catch(() => {
    store.dispatch('triggerToast',{ message: 'Failed to upload sponsor images', type: 'error'})
  })
}

const triggerUpgradeModal = () => {
  const subscriptionDetails = store.getters.getEventSubscriptionDetails
  if (store.getters.hasRemainingPaidEvents(subscriptionDetails?.plan?.name)) {
    store.dispatch('ToggleModalUpgradeEvent', { eventId: store.getters.ebEventId })
  } else {
    store.dispatch('ToggleModalUpgradePayment', {
      eventId: store.getters.ebEventId,
      currentPlan: subscriptionDetails?.plan?.name,
    })
  }
}

const beforeUnloadHandler = async (event) => {
  if (store.getters.isSponsorsUpdated) {
    event.preventDefault()
    // Allow the page to be unloaded
    event.returnValue = true
  }
}

onUnmounted(() => {
  store.dispatch('resetSponsorsState')
  // Remove beforeunload event listener when the component is unmounted
  removeEventListener("beforeunload", beforeUnloadHandler)
})

onBeforeRouteLeave((to, from, next) => {
  if(store.getters.isSponsorsUpdated){
    const payload = {
      confirmAction: () => {
        store.dispatch('ResetModal')
        next()
      },
      cancelAction: () => {
        store.dispatch('ResetModal')
      }
    }
    store.dispatch('ToggleModalUnsavedAlert', payload)
  } else {
    next()
  }
})
</script>

<template>
  <event-page-base>
    <template #title>
      Sponsors
    </template>
    <template v-if="sponsorList.length > 0" #description>
      Here you can add up to 3 sponsors to your Project showcase. They will be displayed at the bottom of your site, on all pages.
    </template>
    <template #error-message>
    </template>
    <template #content>
      <div v-if="!isSectionReady">Loading</div>
      <template v-else>
        <card-event-builder v-if="sponsorList.length === 0"
                            image="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Fsponsors%2Fhandshake.png?alt=media&token=b0d0e6d0-ed24-40b9-b14a-d22bd0296ce0"
                            title="Support Your Event By Inviting Sponsors to Participate"
                            content="Create and manage up to 3 sponsors to list them on your event homepage."
                            :is-create="true"
                            :btn-text="sponsorFeatureEnabled ? 'Add Sponsors' : 'Upgrade'"
                            :btn-disabled="sponsorFeatureEnabled && sponsorListStatus !== processStatuses.LOADED"
                            :functions="sponsorFeatureEnabled ? addSponsors : triggerUpgradeModal"/>
        <create-table v-else />
      </template>
    </template>
    <template #bottom-nav-container>
      <base-button id="bottom-nav-prev"
                   name="bottom-nav-prev"
                   class-name="bottom-nav-button"
                   btn-type="text"
                   :functions="prev"
                   :text="$t('eventBottomNav.back')">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-left" />
            </span>
            {{ $t('eventBottomNav.back') }}
          </span>
        </template>
      </base-button>
      <base-button :text="$t('eventBottomNav.updateBtn')"
                   :disabled="uploadButtonIsDisabled"
                   :is-loading="uploadButtonIsLoading"
                   aria-label="Update Button"
                   class-name="create-sponsors-button"
                   :functions="saveSponsors" />
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.create-sponsors {
  &-button {
    @apply capitalize px-6 py-2 mt-1;
  }
}
</style>

