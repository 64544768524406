import axios from 'axios'
import { config } from '@/config'

class ProjectService {
  getProject({ templateID, include, isTemplate }) {
    return axios.get(`${config.API_URL}/project/${templateID}`, {
      params: {
        include: include,
        isTemplate: isTemplate
      }
    })
  }

  getProjects({ pageSize, nextPageToken, include, isTemplate }) {
    return axios.get(`${config.API_URL}/projects`, {
      params: {
        pageSize: pageSize,
        nextPageToken: nextPageToken,
        include: include,
        isTemplate: isTemplate
      }
    })
  }

  getParticipantProjects(eventId, includes = 'user') {
    return axios.get(`${config.API_URL}/projects`, {
      params: {
        includes: includes,
        submissionStatus: '',
        eventId: eventId,
        pageSize: 1000
      }
    })
  }

  getParticipantProjectCounts(eventId) {
    return axios.get(`${config.API_URL}/participants-project-counts`, {
      params: {
        eventId: eventId
      }
    })
  }

  updateProject(projectId, data, updateMask ) {
    return axios.patch(`${config.API_URL}/projects/${projectId}`, data, updateMask ? {
      params: {
        "updateMask": updateMask
      }
    } : null)
  }

  updateProjectDetails(projectId, data) {
    return this.updateProject(projectId, data, "imageUrl,title,description")
  }

  updateProjectInstructions(projectId, data) {
    return this.updateProject(projectId, data, "instruction_section")
  }

  UpdateProjectStatuses({ projectId, submissionStatus, notes, updateMask = "" }) {
    const payload = {
      submissionStatus: submissionStatus,
      notes: notes
    }
    if (updateMask && updateMask !== "") {
        payload.updateMask = updateMask
    }
    return axios.patch(`${config.API_URL}/projects/${projectId}/updateProjectStatuses`, payload)
  }

  updateProjectVideoAndInstruction(projectId, data) {
    return this.updateProject(projectId, data, "video_url,instruction_video")
  }
  
  cloneProject({ projectId, eventId, isTemplate = false }) {
    return axios.post(`${config.API_URL}/projects/copy/${projectId}`, {eventId: eventId, isTemplate: isTemplate})
  }

  deleteProject({ projectId }) {
    return axios.delete(`${config.API_URL}/projects/${projectId}`)
  }

  sendProjectSubmissionsStatusEmail(eventId, projectIds) {
    const payload = {
        eventId: eventId
    }
    if (projectIds) {
      payload.projectIds = projectIds
    }

    return axios.post(`${config.API_URL}/projects/sendProjectSubmissionsStatusEmail`, payload)
  }

  deleteUserFromEventProjects({ userId, eventId }) {
    return axios
        .delete(`${config.API_URL}/delete-user-from-event-projects`, {
          params: {
            "userId": userId,
            "eventId": eventId
          }
        })
  }

}


export default new ProjectService()
