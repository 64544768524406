<script setup>
// Custom components
import EventPageBase from '@/pages/EventBuilder/EventPageBase'
import PlagiarismScans from '@/components/PlagiarismScans/List'
import { computed } from 'vue';
import store from '@/store';
import { eventFeatureCodes } from '@/utils';
import CardEventBuilder from '@/components/Base/Cards/EventBuilder/index.vue';

const isEnablePlagiarism = computed(() => {
  const feature = store.getters.getEventFeature(eventFeatureCodes.ENABLE_PLAGIARISM_CHECK)
  return feature?.value === 1
})

const triggerUpgradeModal = () => {
  const subscriptionDetails = store.getters.getEventSubscriptionDetails
  if (store.getters.hasRemainingPaidEvents(subscriptionDetails?.plan?.name)) {
    store.dispatch('ToggleModalUpgradeEvent', { eventId: store.getters.ebEventId })
  } else {
    store.dispatch('ToggleModalUpgradePayment', {
      eventId: store.getters.ebEventId,
      currentPlan: subscriptionDetails?.plan?.name,
    })
  }
}
</script>

<template>
  <event-page-base :is-visible="false">
    <template #title>
      Similarity Scans BETA
    </template>
    <template #description>
      Scan submitted projects for potential plagiarism and citation errors on images and text.
      Results will also be reflected in the <router-link class="plagiarism-text" :to="{name: 'Submissions'}">
      Project Submissions</router-link> page.
    </template>
    <template #content>
      <card-event-builder v-if="!isEnablePlagiarism"
                          image="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Fscan.png?alt=media&token=aad2d255-730e-4c0e-872d-f20a491714fb"
                          title="Upgrade to a Paid Plan to Unlock Similarity Scans"
                          content="You'll be able to scan all projects submitted to your showcase"
                          :is-create="true"
                          btn-text="Upgrade"
                          :functions="triggerUpgradeModal"/>
      <plagiarism-scans v-else />
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.plagiarism {
  &-text {
    &:hover {
      @apply underline;
    }
  }
}
</style>
