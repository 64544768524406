<script setup>
// Required Components
import BaseButton from '@/components/Base/Button/BaseButton'
import SearchField from '@/components/Base/Fields/SearchField'
import { computed } from 'vue'
import store from '@/store'

defineProps({
  headers: {
    type: Array,
    required: true
  },
  items: {
    type: Array,
    required: true
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  hasColor: {
    type: Boolean,
    default: false
  },
  updateRowName: {
    type: Function,
    required: false,
    default: () => {}
  },
  toggleIconsModal: {
    type: Function,
    required: false,
    default: () => {}
  },
  deleteRow: {
    type: Function,
    required: false,
    default: () => {}
  },
  invertColor: {
    type: Boolean,
    default: true
  },
  inputPlaceHolder: {
    type: String,
    default: 'New Category'
  },
  sortFunction: {
    type: Function,
    required: true,
    default: () => {}
  },
  fullWidth: {
    type: Boolean,
    default: true
  },
  selectId : {
    type: String,
    default: ''
  }
})

const searchValue = computed(() => {
  return store.getters.getSearchParticipants
})

const searchTeam = computed({
  get() {
    return searchValue.value
  },
  set(newValue) {
    store.dispatch('setSearchParticipants', newValue)
  }
})
</script>

<template>
  <table class="table" :class="{ 'w-full': fullWidth }">
    <thead>
    <tr>
      <th v-for="header in headers"
          :key="header.text"
          class="table-headers"
          :class="header.class ?? ''">
        {{header.text}}
        <base-button v-if="header.sortable"
                     :id="`table-${header.value}}`"
                     :name="`table-${header.value}}`"
                     :text="header.value"
                     btn-type="text"
                     class-name="table-sortBtn"
                     :functions="() => sortFunction(header.value)">
          <template #contents>
            <i class="fa-solid fa-arrow-down-arrow-up"></i>
          </template>
        </base-button>
        <search-field v-if="header.searchable"
                      v-model="searchTeam"
                      input-id="dashboard-Search"
                      input-name="dashboard-Search"
                      input-place-holder="Search"
                      class="table-searchable">
        </search-field>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in items"
        :key="item.id"
        class="table-items"
        :class="{'table-items-selected': selectId === item.id}">
      <slot name="content-column" :item="item"></slot>
      <td>
        <slot name="action-column" :item="item"></slot>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
.table {
  @apply mt-12 mb-44 border-collapse text-sm max-w-[55rem];
  &-sortBtn {
    @apply w-auto text-greys-300;
    &:hover {
      @apply text-blacks-500;
    }
  }
  &-headers {
    @apply text-left text-p-sm text-blacks-500 font-bold pb-4 last:px-4 md:last:px-0 capitalize;
  }
  &-picker {
    @apply pl-20;
  }
  &-items {
    @apply border-b border-t h-[4.5rem];
    &-container {
      @apply md:ml-0 ml-4 flex flex-row w-full justify-between;
    }
    &-selected {
      @apply bg-gray-200;
    }
  }
  &-button {
    @apply w-auto text-blacks-500 min-w-[2.5rem];
  }
}
</style>
